import tw, { styled } from 'twin.macro';
import { Button } from '../../../../styles/components';

export const CompanionPanelButton = styled(Button)<{ isVisible: boolean }>(
  ({ isVisible }) => [
    tw`transform scale-0 origin-center transition-transform ml-auto`,
    isVisible && tw`scale-100`,
  ]
);

export const CompanionPanelSubTitle = tw.h2`font-medium text-sm -mb-2`;
