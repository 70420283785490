const TeleportToSalesmanSVG: React.FC<{ color: string }> = ({
  color = '#fff',
}) => {
  return (
    <svg
      version='1.1'
      width={20}
      height={20}
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      viewBox='0 0 64 64'
      xmlSpace='preserve'
    >
      <path
        id='Union_2473_1_'
        fill={color}
        d='M32,13.7l15.5,15.5h-0.8h-3v3v15.3H20.3V32.2v-3h-3h-0.8L32,13.7 M32,9.5L9.3,32.2h8v18.3
	h29.4V32.2h8L32,9.5L32,9.5z'
      />
      <path
        fill={color}
        d='M37.6,33.4c-0.5-0.5-1.4-0.5-2,0l-2.2,2.2v-6.4c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4v6.4l-2.2-2.2
	c-0.5-0.5-1.4-0.5-2,0c-0.5,0.5-0.5,1.4,0,2L31,40c0,0,0,0,0,0l0,0c0,0,0,0,0.1,0c0.1,0.1,0.2,0.2,0.4,0.3c0.2,0.1,0.4,0.1,0.5,0.1
	c0.2,0,0.4,0,0.5-0.1c0,0,0,0,0,0c0.2-0.1,0.3-0.2,0.4-0.3l4.6-4.6C38.2,34.9,38.2,34,37.6,33.4z'
      />
    </svg>
  );
};

export default TeleportToSalesmanSVG;
