import { Section } from '../../../styles/components';
import 'twin.macro';
import { useContext } from 'react';
import SummaryGroup from './SummaryGroup';
import { CanvasContext } from '../../../context/CanvasContext';

const HouseConfiguratorSummary = () => {
  const {
    canvasState: { modificationDataByCategory },
  } = useContext(CanvasContext);

  return (
    <>
      <Section tw='xl:pl-0'>
        <div>
          <SummaryGroup
            name='Fassade'
            linkValue='facade'
            config={modificationDataByCategory.Facade}
          />
          <SummaryGroup
            name='Dach'
            linkValue='roof'
            config={modificationDataByCategory.Roof}
          />
          <SummaryGroup
            name='Fenster und haustüren'
            linkValue='windows-door'
            config={modificationDataByCategory.WindowsAndDoors}
          />
        </div>
      </Section>
    </>
  );
};

export default HouseConfiguratorSummary;
