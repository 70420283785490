import { Section } from '../../../styles/components';
import 'twin.macro';
import { useLocation } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { configuratorSteps } from '../configuratorSteps.json';
import {
  ConfigurationStepsNavigationFooter,
  NavigationButton,
  NavigationButtonsWrapper,
  NavigationLinkWithQuery,
} from './styles';
import { OrderFormContext } from '../../../context/OrderFormContext';
import { TranslationContext } from '../../../context/TranslationContext';
import { CanvasContext } from '../../../context/CanvasContext';

const ConfigurationStepsNavigation: React.FC = () => {
  const { translate } = useContext(TranslationContext);
  const {
    canvasState: { activeConfiguratorStep },
  } = useContext(CanvasContext);
  const {
    canvasState: { isMobileVendor },
  } = useContext(CanvasContext);
  const [prevLink, setPrevLink] = useState('');
  const [nextLink, setNextLink] = useState('');
  const [isOrderButtonDisabled, setIsOrderButtonDisabled] = useState(true);
  const { pathname } = useLocation();

  // On mobile devices filter out layout step
  const steps = isMobileVendor
    ? configuratorSteps.filter((step) => step.name !== 'Layout')
    : configuratorSteps;

  const indexOfCurrentStep = steps
    .map((step: any) => step.name)
    .indexOf(activeConfiguratorStep);

  const { orderFormState } = useContext(OrderFormContext);

  // Indicate whether form may be submittet or not
  // - check form errors from form /summary/order
  useEffect(() => {
    const formErrors = orderFormState.errors;
    const values = orderFormState.values;

    if (
      !values.firstName ||
      !values.lastName ||
      !values.phone ||
      !values.email ||
      !values.postCode ||
      !values.street ||
      !values.houseNumber ||
      !values.isContactAccepted
    ) {
      return setIsOrderButtonDisabled(true);
    }

    // Check errors
    if (Object.keys(formErrors).length > 0) setIsOrderButtonDisabled(true);
    else setIsOrderButtonDisabled(false);
  }, [orderFormState]);

  // Based on current configurator name step get next, and previous links
  useEffect(() => {
    function setNextAndPrevLinks() {
      const nextIndex = indexOfCurrentStep + 1;
      if (nextIndex < steps.length) {
        const newNextLink = `/configurator/${steps[nextIndex]?.value}`;
        setNextLink(newNextLink);
      }
      const prevIndex = indexOfCurrentStep - 1;
      if (prevIndex > -1) {
        const newPrevLink = `/configurator/${steps[prevIndex]?.value}`;

        if (pathname === '/configurator/summary/order')
          setPrevLink(`/configurator/summary`);
        else setPrevLink(newPrevLink);
      }
    }
    setNextAndPrevLinks();
  }, [indexOfCurrentStep, pathname]);

  return (
    <ConfigurationStepsNavigationFooter>
      <Section>
        <NavigationButtonsWrapper>
          {prevLink && indexOfCurrentStep !== 0 && (
            <NavigationLinkWithQuery to={prevLink}>
              <NavigationButton isLight>
                <i className='fas fa-arrow-left' tw='text-red mr-3' />
                <span>{translate('Previous')}</span>
              </NavigationButton>
            </NavigationLinkWithQuery>
          )}
          {nextLink && !pathname.includes('/configurator/summary') && (
            <NavigationLinkWithQuery to={nextLink}>
              <NavigationButton>
                <span>{translate('Next')}</span>
                <i className='fas fa-arrow-right' tw='pl-3' />
              </NavigationButton>
            </NavigationLinkWithQuery>
          )}

          {pathname === '/configurator/summary' && (
            <NavigationLinkWithQuery to={`/configurator/summary/order`}>
              <NavigationButton>
                <span>{translate('Inquiry')}</span>
                <i className='fas fa-arrow-right' tw='pl-3' />
              </NavigationButton>
            </NavigationLinkWithQuery>
          )}
          {pathname === '/configurator/summary/order' && (
            <span style={{ width: '100%' }}>
              <NavigationButton
                type='submit'
                isDisabled={isOrderButtonDisabled}
                form='inquiryForm'
                disabled={isOrderButtonDisabled}
              >
                <span>{translate('Inquiry')}</span>
                <i className='fas fa-arrow-right' tw='pl-3' />
              </NavigationButton>
            </span>
          )}
        </NavigationButtonsWrapper>
      </Section>
    </ConfigurationStepsNavigationFooter>
  );
};

export default ConfigurationStepsNavigation;
