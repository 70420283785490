import {
  CanvasFullscreenWrapper,
  HouseCanvasPagination,
  HouseCanvasPaginationItem,
  HouseCanvasPaginationWrapper,
  StyledUnity,
} from './styles';
import {
  getCanvasModificationsFromQueryString,
  modificationsToQueryString,
  updateQueryParams,
  useQuery,
} from '../../../utils/helpers';
import { Button } from '../../../styles/components';
import {
  CanvasContext,
  unityContext,
  updateCanvasConfig,
} from '../../../context/CanvasContext';
import CanvasLoadingScreen from './LoadingScreen';
import { RouteComponentProps, useLocation, withRouter } from 'react-router';
import tw from 'twin.macro';
import qs from 'qs';
import { useContext, useEffect, useRef, useState } from 'react';
import { CompanionContext } from '../../../context/CompanionContext';

/** * Component responsible for rendering canvas, alongside with pagination, and navigation
 * @param {boolean} showArrows should canvas display arrows or not
 */

interface IHouseCanvas extends RouteComponentProps {
  showArrows?: boolean;
}

const HouseCanvas: React.FC<IHouseCanvas> = ({
  showArrows = true,
  history,
}) => {
  const {
    canvasState: {
      path,
      currentFloor,
      isInsideHouse,
      isCanvasLoaded,
      canvasHouseInitialized,
      isMobileVendor,
    },
    canvasDispatch,
  } = useContext(CanvasContext);
  const {
    companionCtx: { clientsList, followDevice },
    companionDispatch,
  } = useContext(CompanionContext);

  const { search, pathname } = useLocation();
  const [canvasEl, setCanvasEl] = useState<HTMLCanvasElement>();

  const [configs, setConfigs] = useState<any>([]);
  const [activeSceneIndex, setActiveSceneIndex] = useState(0);
  const [progression, setProgression] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const houseName = useQuery().get('houseName') ?? '';
  const houseRooms = useQuery().get('rooms') ?? '';
  const houseSize = useQuery().get('size') ?? '';

  const canvasWrapperEl = useRef<HTMLDivElement>(null);
  let canvas = unityContext.htmlCanvasElement;
  let sceneLoaded = false;

  const isCanvasHidden = pathname === '/';
  const isSmallCanvas = pathname.includes('/summary');

  const isLayoutPage = pathname.includes('/layout');

  function RescaleCanvas() {
    if (canvasWrapperEl.current && canvas) {
      canvas.width = canvasWrapperEl.current.clientWidth; // * window.devicePixelRatio (this breaks hitbox on some screens like retina)
      canvas.height = canvasWrapperEl.current.clientHeight; // * window.devicePixelRatio
    }
  }

  function FixCanvasScale() {
    RescaleCanvas();
    var requestAnimFrame = (function () {
      return (
        window.requestAnimationFrame ||
        function (callback) {
          window.setTimeout(callback, 1000 / 5);
        }
      );
    })();

    if (!sceneLoaded) requestAnimFrame(FixCanvasScale);
  }

  useEffect(() => {
    if (isCanvasLoaded && houseName) {
      console.log('### Opening scene: ' + houseName);
      unityContext.send('Launcher', 'OpenSceneJson', houseName);
    } else if (isCanvasLoaded && !houseName) {
      setTimeout(() => {
        unityContext.send('Launcher', 'CloseScene');
        console.log('### Closing Scene: ' + houseName);
      }, 200); // Don't do it instantly when changing route to prevent texture flashes when unloading textures
    }

    unityContext.on('progress', (progression: any) => {
      setProgression(progression * 100);
      if (progression === 1) {
        canvas = unityContext.htmlCanvasElement;
        const gl = canvas?.getContext('webgl2');
        canvasDispatch({ type: 'WEBGL_LOADED', payload: gl });

        if (canvas) {
          console.log('Loaded WebGL registering resize listener');
          // Update canvas width/height on window resize
          window.addEventListener('resize', () => {
            RescaleCanvas();
          });
          // set canvas to local state for easy access
          setCanvasEl(canvas);
        }
      }
    });
  }, [houseName, isCanvasLoaded, canvasDispatch]);

  // Check which modifications are available
  useEffect(() => {
    unityContext.on('ValidationData', (jsonData) => {
      let data = JSON.parse(jsonData);
      canvasDispatch({
        type: 'UPDATE_MODIFICATION_FILTERS',
        payload: data.ConfigGroups,
      });
    });
  }, [canvasDispatch]);

  useEffect(() => {
    async function getHouseConfigs() {
      try {
        const res = await fetch(`${path}/Json/HouseCardConfigs.json`);
        const data = await res.json();
        setConfigs(data[houseName]);
      } catch (err) {
        console.log(err);
      }
    }

    getHouseConfigs();
  }, [houseName]);

  // Set initial house config
  useEffect(() => {
    if (!configs?.length || isCanvasHidden) return;

    const params = qs.parse(search);
    if (
      Object.keys(params).length === 3 &&
      !clientsList.find((client) => client.IsSelf)?.IsInSession
    ) {
      let queryString = `houseName=${houseName}&size=${houseSize}&rooms=${houseRooms}${modificationsToQueryString(
        configs[0].Modifications
      )}`;
      updateQueryParams(history, pathname, queryString);
    }
  }, [configs, isCanvasHidden]);

  // Initialize canvas with modifications from query params
  useEffect(() => {
    async function getHouseConfigs() {
      try {
        const res = await fetch(`${path}/Json/HouseCardConfigs.json`);
        const data = await res.json();

        if (houseName) {
          setTimeout(
            () => updateCanvasConfig(data[houseName][0].Modifications),
            500
          );
        }
      } catch (err) {
        console.log(err);
      }
    }
    const modifications = getCanvasModificationsFromQueryString(search);
    if (
      modifications &&
      isCanvasLoaded &&
      canvasHouseInitialized != houseName &&
      !clientsList.find((client) => client.IsSelf)?.IsInSession
    ) {
      canvasDispatch({ type: 'CANVAS_INITIALIZED', payload: houseName });
      if (modifications.length == 0) {
        getHouseConfigs();
      } else {
        setTimeout(() => updateCanvasConfig(modifications), 500);
      }
    }
  }, [search, canvasDispatch, isCanvasLoaded]);

  useEffect(() => {
    unityContext.on('OnLayoutEnter', () => {
      // If we are entering house when in follow mode stop following user
      if (followDevice.UserId || followDevice.ShouldFollow) {
        unityContext.send(
          'Launcher',
          'SalesmanFollowUser',
          JSON.stringify({
            userId: followDevice.UserId,
            shouldFollow: false,
          })
        );
        companionDispatch({
          type: 'SET_FOLLOW_DEVICE',
          payload: { UserId: '', shouldFollow: false },
        });
      }

      // Update state
      canvasDispatch({ type: 'UPDATE_IS_INSIDE_HOUSE', payload: true });
    });
  }, [isInsideHouse, canvasDispatch, followDevice, companionDispatch]);

  useEffect(() => {
    if (pathname === '/') return; // Don't update size on homepage canvas (it's hidden)
    if (canvasEl && canvasWrapperEl.current) {
      // Fix canvas initial size
      setTimeout(() => {
        if (canvasEl && canvasWrapperEl.current) {
          canvasEl.width = canvasWrapperEl.current.clientWidth;
          canvasEl.height = canvasWrapperEl.current.clientHeight;
        }
      });

      // On layout page update markers position after updating canvas width/height
      // Seems to be no longer nessesary and this breaks other things. I real wish we could not break this code
      //isLayoutPage &&
      //  setTimeout(() => {
      //    unityContext.send('Launcher', 'RequestLayoutWindows', currentFloor);
      //  }, 200);
    }
  }, [pathname, currentFloor, canvasEl, isLayoutPage, canvasWrapperEl]);

  useEffect(() => {
    unityContext.on('OnLauncherLoaded', () => {
      canvasDispatch({ type: 'CANVAS_LOADED' });
      console.log('Launcher loaded');
      FixCanvasScale();
    });
  });

  useEffect(() => {
    unityContext.on('OnSceneLoaded', (scene) => {
      console.log(`Scene ${scene} loaded`);
      sceneLoaded = true;

      // Load interior if it's not mobile
      if (!isMobileVendor) {
        console.log('LoadInterior dispatched');
        unityContext.send('Launcher', 'LoadInterior');
      }
    });
  });

  const handleOpenFullscreenCanvas = () => {
    console.log('Opening full screen');
    canvasWrapperEl.current?.requestFullscreen();
    setIsFullscreen(true);
  };

  const handleCloseFullscreenCanvas = () => {
    console.log('Closing full screen');
    document.exitFullscreen();
    setIsFullscreen(false);
  };

  const handleSetActiveScene = (newActiveSceneIndex: number) => {
    // Validation - check if new index in range 0-3
    if (newActiveSceneIndex > 3) newActiveSceneIndex = 0;
    if (newActiveSceneIndex < 0) newActiveSceneIndex = 3;

    setActiveSceneIndex(newActiveSceneIndex);
    unityContext.send('Launcher', 'RotateHouseJson', newActiveSceneIndex);
  };

  const handleExitLayout = () => {
    unityContext.send('Launcher', 'RequestLayoutWindows', currentFloor);
    canvasDispatch({ type: 'UPDATE_IS_INSIDE_HOUSE', payload: false });
  };

  return (
    <div tw='h-full'>
      <div tw='relative h-full' style={{ height: 'calc(100% - 68px)' }}>
        <CanvasFullscreenWrapper ref={canvasWrapperEl}>
          <StyledUnity
            className='unityInstanceEl'
            unityContext={unityContext}
            tw='block'
            isSmallCanvas={isSmallCanvas}
            matchWebGLToCanvasSize={false}
            tabIndex={1}
          />
          <Button
            isCircle
            id='closeFullscreen'
            onClick={handleCloseFullscreenCanvas}
          >
            <i className='fas fa-compress-alt'></i>
          </Button>
          {isLayoutPage && isInsideHouse && !isFullscreen && (
            <Button isCircle id='exitLayout' onClick={handleExitLayout}>
              <i className='fas fa-times'></i>
            </Button>
          )}
        </CanvasFullscreenWrapper>

        {/* Show canvas loading screen (0-30) */}
        {progression < 100 && <CanvasLoadingScreen progress={progression} />}

        {/* Canvas arrows navigation  */}
        {showArrows && !isLayoutPage && (
          <div tw='absolute bottom-5 right-5 flex'>
            <Button
              isCircle
              tw='mr-4'
              onClick={() => handleSetActiveScene(activeSceneIndex - 1)}
            >
              <i className='fas fa-chevron-left' />
            </Button>
            <Button
              isCircle
              onClick={() => handleSetActiveScene(activeSceneIndex + 1)}
            >
              <i className='fas fa-chevron-right' />
            </Button>
          </div>
        )}
      </div>
      {/* Dots pagination */}
      <div tw='border-b xl:border-none relative z-10 bg-white'>
        <HouseCanvasPaginationWrapper>
          <HouseCanvasPagination>
            {!isLayoutPage &&
              Array.from(Array(4).keys()).map((_, index) => (
                <HouseCanvasPaginationItem
                  key={index}
                  onClick={() => handleSetActiveScene(index)}
                  isActive={activeSceneIndex === index}
                ></HouseCanvasPaginationItem>
              ))}
          </HouseCanvasPagination>

          <button
            onClick={handleOpenFullscreenCanvas}
            tw='ml-auto text-red text-3xl cursor-pointer hover:text-darkRed transition-colors'
            disabled={isLayoutPage && !isInsideHouse}
            css={[
              progression === 1 && tw`text-white transform scale-0`,
              isLayoutPage &&
                !isInsideHouse &&
                tw`text-gray-300 hover:text-gray-300 cursor-default`,
            ]}
          >
            <i className='fas fa-expand-alt'></i>
          </button>
        </HouseCanvasPaginationWrapper>
      </div>
    </div>
  );
};

export default withRouter(HouseCanvas);
