import tw, { styled } from 'twin.macro';
import { FormControl } from '../../../styles/components/formComponents';

export const Nav = tw.nav`p-4 border-b relative`;

export const PageTitle = tw.h1`font-bold`;

export const Icon = tw.i`text-2xl text-gray-700 hover:text-gray-900 transition-colors cursor-pointer`;

export const SearchFiltersWrapper = styled.div`
  ${tw`bg-white absolute z-50 top-full left-0 p-4 border-t w-full transform scale-y-0 transition-transform duration-300 origin-top lg:(scale-y-100 static shadow-none border-0 px-0)`}

  box-shadow: 0px 4px 5px 0px rgb(0 0 0 / 20%);
`;

export const MobileMenu = tw.div`flex items-center justify-between lg:hidden`;

export const SelectFormControl = tw(
  FormControl
)`mt-4 shadow-bottom px-3 py-2 rounded lg:(flex-1 mx-4 mb-3 mt-0 p-0 shadow-none border-r)`;

export const SelectIconsWrapper = tw.div`flex items-center pr-1`;

export const SelectIcon = tw.i`absolute block`;

export const Select = styled.select`
  ${tw`py-1 cursor-pointer bg-transparent appearance-none w-full relative block z-10`}

  padding-left: 30px;
  padding-right: 30px;
  outline: none;
`;

export const SearchFiltersForm = tw.form`lg:(flex shadow-bottom rounded-lg)`;
