import React, { createContext, useEffect, useReducer } from 'react';
import { UnityContext } from 'react-unity-webgl';
import { getMobileOperatingSystem } from '../utils/helpers';
import { canvasReducer, ICanvasAction } from './reducers/canvasReducer';
import { IConfiguratorStepName } from '../utils/types';
import configuratorStepsJSON from '../components/Configuration/configuratorSteps.json';

const path = 'Build';
let isBrotliAvailable = true;
const COMPRESSION =
  isBrotliAvailable && process.env.NODE_ENV === 'production' ? '.br' : '';

const isMobileVendor = ['Windows Phone', 'Android', 'iOS'].includes(
  getMobileOperatingSystem()
);
// Determine version of unity that should be loaded, based on mobile vendor. For development purposes, we do not select a version.
const vendorPath =
  process.env.NODE_ENV === 'production'
    ? isMobileVendor
      ? '/mobile'
      : '/desktop'
    : '';

// Check if brotli exists
if (process.env.NODE_ENV === 'production') {
  (async () => {
    try {
      const res = await fetch(
        `${process.env.PRODUCTION_URL}/${path}/Build/WebGL.data.br`
      );
      res.json();
    } catch (e) {
      isBrotliAvailable = false;
    }
  })();
}

const fullPath = `${vendorPath}/${path}`;

export const unityContext = new UnityContext({
  dataUrl: `${fullPath}/Build/WebGL.data${COMPRESSION}`,
  frameworkUrl: `${fullPath}/Build/WebGL.framework.js${COMPRESSION}`,
  codeUrl: `${fullPath}/Build/WebGL.wasm${COMPRESSION}`,
  streamingAssetsUrl: `${fullPath}/StreamingAssets`,
  loaderUrl: `${fullPath}/Build/WebGL.loader.js`,
  companyName: `DefaultCompany`,
  productName: `TownCountryUnity`,
  productVersion: `0.1`,
});

export interface ICanvasState {
  path: string;
  gl: WebGL2RenderingContext | null;
  isCanvasLoaded: boolean;
  canvasHouseInitialized: string;
  isMobileVendor: boolean;
  currentFloor: number;
  configuratorSteps: IConfiguratorStepName[];
  activeConfiguratorStep: IConfiguratorStepName;
  isInsideHouse: boolean;
  activeConfigIndex: number; // active config in House step
  activeIndexes: any;
  modificationDataByCategory: any;
  enabledModifications: any;
  filteredModifications: {
    [key: string]:
      | {
          IsEnabled: Boolean;
          ConfigElements: { [key: string]: { IsEnabled: Boolean } };
        }
      | undefined;
  };
}

interface ICanvasContext {
  canvasState: ICanvasState;
  canvasDispatch: React.Dispatch<ICanvasAction>;
}
export const CanvasContext = createContext<ICanvasContext>(undefined!);

export function updateCanvasConfig(modifications: any[]) {
  console.log('### Sending configuration: ' + JSON.stringify(modifications));

  unityContext.send(
    'Launcher',
    'RequestChangeJson',
    JSON.stringify(modifications)
  );
}

const CanvasContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(canvasReducer, {
    path: fullPath,
    gl: null,
    isCanvasLoaded: false,
    canvasHouseInitialized: '',
    currentFloor: 0,
    isInsideHouse: false,
    activeConfigIndex: 0,
    configuratorSteps: [],
    activeConfiguratorStep: 'House',
    activeIndexes: {},
    modificationDataByCategory: {},
    enabledModifications: {},
    filteredModifications: {},
    isMobileVendor: ['Windows Phone', 'Android', 'iOS'].includes(
      getMobileOperatingSystem()
    ),
  });

  // Every time the active configurator step changes, send message to Unity
  useEffect(() => {
    if (state.activeConfiguratorStep) {
      // find index of active configurator step in configuratorSteps array
      const index: number = state.configuratorSteps.findIndex(
        (step: any) => step.name === state.activeConfiguratorStep
      );
      const realIndex = index + 1;

      if (index !== -1) {
        unityContext.send('Launcher', 'RequestCategoryChanged', realIndex);
      }
    }
  }, [state]);

  // Set configurator steps
  useEffect(() => {
    dispatch({
      type: 'SET_CONFIGURATOR_STEPS',
      payload: configuratorStepsJSON.configuratorSteps,
    });
  }, []);

  return (
    <CanvasContext.Provider
      value={{ canvasState: state, canvasDispatch: dispatch }}
    >
      {children}
    </CanvasContext.Provider>
  );
};

export default CanvasContextProvider;
