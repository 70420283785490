import { Divider, Section, Title } from '../../../styles/components';
import { useLocation } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { TranslationContext } from '../../../context/TranslationContext';
import Autocomplete from 'react-autocomplete';
import axios from 'axios';
import { Formik } from 'formik';
import 'twin.macro';
import * as Yup from 'yup';
import {
  CheckBox,
  DropdownMenuStyles,
  FormGroup,
  Label,
  RadioLabel,
} from './styles';
import {
  ErrorBorder,
  FormErrorMsg,
  Input,
  RadioInput,
  TextArea,
} from '../../../styles/components/formComponents';
import { getAddresses } from './api';
import {
  formInitialValues,
  OrderFormContext,
} from '../../../context/OrderFormContext';
import { getCurrentDate } from '../../../utils/helpers';

// - - - - - - - - - - - - - - - - - - - - - - - - - -
// Component used for sending inquries about houses
// - - - - - - - - - - - - - - - - - - - - - - - - - -

const OrderForm: React.FC<RouteComponentProps> = ({ history }) => {
  const { translate } = useContext(TranslationContext);
  const { orderFormState, setOrderFormState } = useContext(OrderFormContext);

  const [addresses, setAddresses] = useState([]);
  const { search } = useLocation();

  // Form validation schema - edit rules here, and add max to certain inputs
  const [OrderSchema] = useState(() => {
    const err = translate('Invalid input');

    return Yup.object().shape({
      firstName: Yup.string().min(2, err).max(40, err).required(err),
      lastName: Yup.string().min(2, err).max(40, err).required(err),
      phone: Yup.string()
        .min(10, err)
        .max(22, err)
        .matches(/^(\(?([\d \-)–+/(]+)\)?([ .\-–/]?)([\d]+))+$/, err)
        .required(err),
      email: Yup.string().email(err).min(6).max(50).required(err),
      postCode: Yup.string().min(5, err).max(5, err).required(err),
      city: Yup.string().min(2, err).max(50, err).required(err),
      street: Yup.string().min(2, err).max(50, err).required(err),
      houseNumber: Yup.string().max(7, err).required(err),
      description: Yup.string().notRequired().max(1000, err),
      isContactAccepted: Yup.boolean().isTrue(err),
    });
  });

  return (
    <Formik
      initialValues={formInitialValues}
      validateOnBlur={true}
      validationSchema={OrderSchema}
      onSubmit={async (values) => {
        const react_app_url = process.env.REACT_APP_API_URL ?? "https://api.towncountry.de:5500";
        const buildingLeadURL = `${react_app_url}/user-management/v1/building-lead`;
        const configurationURL = `${window.location.protocol}//${window.location.host}/configurator/house${search}`;

        const token = localStorage.getItem('order_token');
        try {
          await axios.post(
            buildingLeadURL,
            {
              firstName: values.firstName,
              lastName: values.lastName,
              city: values.city,
              postalCode: values.postCode,
              email: values.email,
              salutation: values.gender === 'She' ? 'Frau' : 'Herr',
              street: values.street,
              firstContactCategory: 'Webseiten',
              firstContactDate: getCurrentDate(),
              gdprConSensToDataProcessing: 'Ja',
              subject: 'TC-Webkonfigurator',
              phoneNumber: values.phone,
              description: values.description,
              firstContactSource: 'TC.de',
              firstContactDescription: 'Webkonfigurator',
              webConfigURL: configurationURL,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          // Clear form
          orderFormState.clearForm();

          history.push('/?inquiry=success');
        } catch (e) {
          console.log(e);
        }
      }}
    >
      {function ShowForm({
        handleChange,
        setFieldValue,
        handleSubmit,
        handleBlur,
        setTouched,
        values,
        errors,
        touched,
      }) {
        // Send request to get autocompletion whenever new value is beeing received (postcode, city)
        useEffect(() => {
          // Deley request to prevent spam
          const delayRequest = setTimeout(async () => {
            const { addresses } = await getAddresses(
              values.postCode,
              values.city
            );
            setAddresses(addresses.slice(0, 12));
          }, 400);

          return () => clearTimeout(delayRequest);
        }, [values.postCode, values.city]);

        // Keep global form context up to date with local updates
        useEffect(() => {
          setOrderFormState({ ...orderFormState, values, errors });
        }, [errors, values]);

        return (
          <form id='inquiryForm' onSubmit={handleSubmit}>
            <Section>
              <Title>{translate('Personal')}</Title>
              <Divider />
              <FormGroup>
                <Label htmlFor='gender'>{translate('Gender')}</Label>
                <RadioLabel>
                  <RadioInput
                    type='radio'
                    name='gender'
                    onChange={handleChange}
                    value='He'
                    defaultChecked
                  />{' '}
                  {translate('Male')}
                </RadioLabel>
                <RadioLabel>
                  <RadioInput
                    type='radio'
                    name='gender'
                    onChange={handleChange}
                    value='She'
                  />{' '}
                  {translate('Female')}
                </RadioLabel>
              </FormGroup>

              <div tw='md:(flex items-center)'>
                <FormGroup
                  tw='md:(w-1/3)'
                  hasError={!!errors.firstName && !!touched.firstName}
                >
                  <Label htmlFor='firstName' isRequired>
                    {translate('First name')}
                  </Label>
                  <Input
                    type='text'
                    placeholder='Vorname'
                    id='firstName'
                    maxLength={40}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    hasError={!!errors.firstName && touched.firstName}
                  />
                  <FormErrorMsg
                    isVisible={!!errors.firstName && !!touched.firstName}
                  >
                    {errors.firstName}
                  </FormErrorMsg>
                </FormGroup>

                <FormGroup
                  tw='md:(w-1/3 pl-2)'
                  hasError={!!errors.lastName && touched.lastName}
                >
                  <Label tw='mt-4 md:mt-0' htmlFor='lastName' isRequired>
                    {translate('Last name')}
                  </Label>
                  <Input
                    type='text'
                    placeholder='Nachname'
                    maxLength={40}
                    id='lastName'
                    value={values.lastName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    hasError={!!errors.lastName && touched.lastName}
                  />
                  <FormErrorMsg
                    isVisible={!!errors.lastName && !!touched.lastName}
                  >
                    {errors.lastName}
                  </FormErrorMsg>
                </FormGroup>
              </div>

              <FormGroup
                tw='md:w-1/3'
                hasError={!!errors.phone && !!touched.phone}
              >
                <Label htmlFor='phone' isRequired>
                  {translate('Phone number')}
                </Label>
                <Input
                  type='text'
                  placeholder='0000 - 000 00 0'
                  id='phone'
                  value={values.phone}
                  maxLength={22}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  hasError={!!errors.phone && !!touched.phone}
                />
                <FormErrorMsg isVisible={!!errors.phone && !!touched.phone}>
                  {errors.phone}
                </FormErrorMsg>
              </FormGroup>

              <FormGroup
                tw='md:w-2/3'
                hasError={!!errors.email && !!touched.email}
              >
                <Label htmlFor='email' isRequired>
                  {translate('Email address')}
                </Label>
                <Input
                  type='text'
                  placeholder='address@domain.de'
                  id='email'
                  value={values.email}
                  maxLength={50}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={!!errors.email && !!touched.email}
                />
                <FormErrorMsg isVisible={!!errors.email && !!touched.email}>
                  {errors.email}
                </FormErrorMsg>
              </FormGroup>
            </Section>

            {/* Adresse */}
            <Section tw='mt-8'>
              <Title>{translate('Address')}</Title>
              <Divider />
              <div tw='flex space-x-4'>
                <FormGroup tw='w-1/4' hasError={!!errors.postCode}>
                  <Label isRequired htmlFor='postCode'>
                    {translate('Post code')}
                  </Label>
                  <Autocomplete
                    getItemValue={(item) => item.displayName}
                    items={addresses}
                    renderItem={(item, isHighlighted) => (
                      <div
                        key={item.displayName}
                        style={{
                          cursor: 'pointer',
                          background: isHighlighted ? 'lightgray' : 'white',
                        }}
                      >
                        {item.displayName}
                      </div>
                    )}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const lastChar =
                        e.target.value[e.target.value.length - 1];
                      const isNumber = /^[0-9]$/i.test(lastChar);
                      if (isNumber || !e.target.value) {
                        handleChange(e);
                      }
                    }}
                    shouldItemRender={(item, value) =>
                      item.displayName
                        .toLowerCase()
                        .includes(value.toLowerCase())
                    }
                    value={values.postCode}
                    renderMenu={(items) =>
                      items.length ? (
                        <div style={DropdownMenuStyles} children={items} />
                      ) : (
                        <div />
                      )
                    }
                    onSelect={(val) => {
                      setFieldValue('postCode', val.split(' - ')[0]);
                      setFieldValue('city', val.split(' - ')[1]);
                    }}
                    renderInput={(props: any) => (
                      <Input
                        {...props}
                        type='text'
                        onKeyPress={(e) => {
                          const isNumber = /^[0-9]$/i.test(e.key);
                          if (isNumber) {
                            handleChange(e);
                          }
                        }}
                        placeholder={translate('Post code')}
                        tw='appearance-none'
                        css={{ WebkitAppearance: 'none' }}
                        id='postCode'
                        value={values.postCode}
                        hasError={!!errors.postCode}
                        maxLength={5}
                      />
                    )}
                  />
                  <FormErrorMsg isVisible={!!errors.postCode}>
                    {errors.postCode}
                  </FormErrorMsg>
                </FormGroup>
                <FormGroup
                  tw='w-3/4'
                  hasError={!!errors.city && !!touched.city}
                >
                  <Label isRequired htmlFor='city'>
                    {translate('Residence')}
                  </Label>
                  <Autocomplete
                    getItemValue={(item) => item.displayName}
                    items={addresses}
                    renderItem={(item, isHighlighted) => (
                      <div
                        key={item.displayName}
                        style={{
                          cursor: 'pointer',
                          background: isHighlighted ? 'lightgray' : 'white',
                        }}
                      >
                        {item.displayName}
                      </div>
                    )}
                    onChange={handleChange}
                    wrapperStyle={{
                      width: '100%',
                    }}
                    shouldItemRender={(item, value) =>
                      item.displayName
                        .toLowerCase()
                        .includes(value.toLowerCase())
                    }
                    value={values.city}
                    renderMenu={(items) =>
                      items.length ? (
                        <div
                          style={{ ...DropdownMenuStyles, width: '100%' }}
                          children={items}
                        />
                      ) : (
                        <div />
                      )
                    }
                    onSelect={(val) => {
                      let postCode;
                      let city;

                      if (val.includes('(')) {
                        postCode = val.split('(')[1]?.slice(0, -1);
                        city = val.split('(')[0]?.slice(0, -1);
                      } else {
                        postCode = val.split(' - ')[0];
                        city = val.split(' - ')[1];
                      }

                      setFieldValue('postCode', postCode);
                      setFieldValue('city', city);
                    }}
                    renderInput={(props: any) => (
                      <Input
                        {...props}
                        type='text'
                        placeholder={translate('Residence')}
                        id='city'
                        value={values.city}
                        maxLength={50}
                        hasError={!!errors.city && !!touched.city}
                      />
                    )}
                  />

                  <FormErrorMsg isVisible={!!errors.city && !!touched.city}>
                    {errors.city}
                  </FormErrorMsg>
                </FormGroup>
              </div>
              <div tw='flex space-x-4'>
                <FormGroup
                  tw='w-2/3'
                  hasError={!!errors.street && !!touched.street}
                >
                  <Label isRequired htmlFor='street'>
                    {translate('Street')}
                  </Label>
                  <Input
                    type='text'
                    placeholder='Straße'
                    id='street'
                    maxLength={50}
                    value={values.street}
                    onBlur={handleBlur}
                    hasError={!!errors.street && !!touched.street}
                    onChange={handleChange}
                  />
                  <FormErrorMsg isVisible={!!errors.street && !!touched.street}>
                    {errors.street}
                  </FormErrorMsg>
                </FormGroup>
                <FormGroup
                  tw='w-1/3'
                  hasError={!!errors.houseNumber && !!touched.houseNumber}
                >
                  <Label isRequired htmlFor='houseNumber'>
                    {translate('House number')}
                  </Label>
                  <Input
                    type='text'
                    placeholder='Nr'
                    id='houseNumber'
                    maxLength={7}
                    value={values.houseNumber}
                    onBlur={handleBlur}
                    hasError={!!errors.houseNumber && !!touched.houseNumber}
                    onChange={handleChange}
                  />
                  <FormErrorMsg
                    isVisible={!!errors.houseNumber && !!touched.houseNumber}
                  >
                    {errors.houseNumber}
                  </FormErrorMsg>
                </FormGroup>
              </div>
            </Section>
            {/* Kommentare un Anregungen */}

            <Section tw='mt-8'>
              <Title>{translate('Comments and suggestions')}</Title>
              <Divider />
              <FormGroup
                hasError={!!errors.description && !!touched.description}
              >
                <Label>{translate('Suggestiong and comments')}</Label>
                <TextArea
                  rows={8}
                  value={values.description}
                  name='description'
                  onBlur={handleBlur}
                  hasError={!!errors.description && !!touched.description}
                  onChange={handleChange}
                  placeholder={translate(
                    '(e.g. questions about energy saving)'
                  )}
                  maxLength={1000}
                />
                <div tw='text-right text-sm'>
                  {values.description.length}/1000
                </div>

                <FormErrorMsg
                  isVisible={!!errors.description && !!touched.description}
                >
                  {errors.description}
                </FormErrorMsg>
              </FormGroup>
              <Divider />
              <FormGroup>
                <Label htmlFor='isContactAccepted'>
                  {translate('Contact')}
                </Label>
                <div tw='flex relative'>
                  <CheckBox
                    type='checkbox'
                    id='isContactAccepted'
                    tw='mt-1'
                    checked={values.isContactAccepted}
                    onChange={handleChange}
                    hasError={
                      !!errors.isContactAccepted && !!touched.isContactAccepted
                    }
                  />
                  <ErrorBorder
                    isVisible={
                      !!errors.isContactAccepted && !!touched.isContactAccepted
                    }
                  >
                    <p tw='pl-2'>
                      {translate(
                        'I / we agree to being contacted by email and telephone and hereby consent to the processing (storage, use, utilization and transmission) of my / our above data for the purpose of contacting the Town & Country Haus licensor GmbH, Hauptstrasse 90 E, 99820 Hörselberg-Hainich OT Behringen and its partner companies (Town & Country Customer Service GmbH, Schmidtstedter Str. 34, 99084 Erfurt, license and / or franchise partner). Contact is only made in order to send me / us information and personalized offers relating to house building by letter, email, phone, chat or a personal contact person. I / we can revoke this consent at any time for the future. I / we can object to the use of my data for advertising purposes at any time. I have taken note of the applicable data protection declaration. '
                      )}
                    </p>
                  </ErrorBorder>
                </div>
              </FormGroup>
              <Divider />
              <span tw='text-red font-bold pb-4'>
                {translate('* Mandatory')}
              </span>
            </Section>
          </form>
        );
      }}
    </Formik>
  );
};

export default OrderForm;
