import { useCallback, useContext, useEffect, useState } from 'react';
import { Divider, Title } from '../../../styles/components';
import {
  CloseModalButton,
  CloseModalIcon,
  ModalBox,
  ModalHeader,
  ModalWrapper,
} from './styles';
import 'twin.macro';
import { TranslationContext } from '../../../context/TranslationContext';
import { RouteComponentProps, useLocation, withRouter } from 'react-router-dom';

/**
 * Modal that is shown when the inquiry is successfully sent
 */

interface IInquiryConfirmationModal extends RouteComponentProps<any> {}

const InquiryConfirmationModal: React.FC<IInquiryConfirmationModal> = ({
  history,
}) => {
  const { translate } = useContext(TranslationContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    if (query.get('inquiry') === 'success') {
      setIsModalOpen(true);
      document.body.classList.toggle('no-scroll');
    }
  }, [search]);

  const toggleModal = () => {
    setIsModalOpen(false);
    document.body.classList.toggle('no-scroll');
    history.push('/');
  };

  return (
    <ModalWrapper isModalOpen={isModalOpen}>
      <ModalBox isModalOpen={isModalOpen}>
        <ModalHeader>
          <Title>{translate('Many Thanks')}</Title>
          <Divider />
          <CloseModalButton onClick={toggleModal}>
            <CloseModalIcon className='fas fa-times'></CloseModalIcon>
          </CloseModalButton>
        </ModalHeader>
        <p>
          {translate(
            'We have received your message and will take care of it as soon as possible '
          )}
        </p>
      </ModalBox>
    </ModalWrapper>
  );
};

export default withRouter(InquiryConfirmationModal);
