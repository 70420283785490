import { CSSProperties } from 'styled-components';
import tw, { css, styled } from 'twin.macro';

export const Label = styled.label<{ isRequired?: boolean }>(
  ({ isRequired }) => [
    tw`font-bold cursor-pointer`,
    isRequired &&
      css`
        &::after {
          content: '*';
          color: red;
        }
      `,
  ]
);

export const CheckBox = styled.input<{ hasError?: boolean }>(({ hasError }) => [
  css`
    cursor: pointer;
    appearance: none;
    display: block;
    padding: 10px;

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 22px;
      height: 22px;
      border-radius: 3px;

      border: 2px solid ${hasError ? 'red;' : '#5a5a5a;'};
      z-index: 1;
      top: 0;
      left: 0;
    }

    &:checked:before {
      content: '\f00c';
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      position: absolute;
      z-index: 2;
      top: 1px;
      left: 5px;
      color: #e20000;
      font-size: 14px;
    }
  `,
]);

export const RadioLabel = tw.label`cursor-pointer`;

interface IFormGroup {
  hasError?: boolean;
}

export const FormGroup = styled.div<IFormGroup>(({ hasError }) => [
  css`
    ${tw`mb-7 relative`}

    label:first-child {
      ${tw`mb-2`}
    }

    label {
      width: 100%;
      display: block;
    }
  `,

  hasError &&
    css`
      &:after {
        content: '\f071';
        position: absolute;
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        top: 41px;
        right: 16px;
        color: #e20000;
        width: 10px;
        height: 10px;
        z-index: 1;
      }
    `,
]);

export const DropdownMenuStyles: CSSProperties = {
  position: 'absolute',
  top: '100%',
  left: 0,
  width: '400px',
  borderRadius: '3px',
  border: '1px solid #575757',
  boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
  background: 'rgba(255, 255, 255, 0.9)',
  fontSize: '90%',
  zIndex: 1,
};
