import { useContext, useEffect, useState } from 'react';
import {
  ArrowsNavigationWrapper,
  SliderArrow,
  SliderCounterIndex,
  SliderWrapper,
} from './styles';
import { Section, Title } from '../../../styles/components';
import HouseListItem from '../HouseListItem';
import 'twin.macro';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import sliderSettings from './sliderSettings.json'; // https://swiperjs.com/swiper-api
import { IHouse } from '../../../utils/types';
import { CompareHouseCategory } from '../../../utils/helpers';
import { TranslationContext } from '../../../context/TranslationContext';
import { CanvasContext } from '../../../context/CanvasContext';
SwiperCore.use([Navigation, Pagination]);

/**
 * Component responsible for showing list of houses
 * @param {string} houseCategory category of the house visible at the top right corner of each house carousel
 * @param {number} minHouseSize miniumum house size value used in filtering out unsuitable houses
 * @param {number} maxHouseSize maximum house size value used in filtering out unsuitable houses
 * @param {number} houseCategory category of the house visible at the top right corner of each house carousel
 */

interface IHouseList {
  houseCategory: string;
  minHouseSize: number | null;
  maxHouseSize: number | null;
}

const HouseList: React.FC<IHouseList> = ({
  houseCategory,
  minHouseSize,
  maxHouseSize,
}) => {
  const { canvasState: {path} } = useContext(CanvasContext);
  const { translate } = useContext(TranslationContext);
  const [slider, setSlider] = useState<SwiperCore>(); // Slider instance with all methods and properties https://swiperjs.com/swiper-api e.g. slider?.slideNext()
  const [currentSlideIndex, setCurrentSlideIndex] = useState(1); // Responsible for counter displayed above the slider
  const [allHouses, setAllHouses] = useState<IHouse[]>([]); // Array of houses - used for rendering slides in the carousel
  const [filteredHouses, setFilteredHouses] = useState<IHouse[]>([]); // Array of houses - used for rendering slides in the carousel

  // Get houses data from local JSON file
  useEffect(() => {
    async function getHousesData() {
      try {
        const res = await fetch(`${path}/Json/HouseCards.json`);
        const data = await res.json();
        setAllHouses(data);
      } catch (err) {
        console.log(err);
      }
    }
    getHousesData();
  }, []);

  // Filter houses whenever fliters change (HAUSSUCHE click occur)
  useEffect(() => {
    // Filter out slides that don't match filters - house size and category
    const tempFilteredHouses = allHouses.filter((house: IHouse) => {
      const isCategoryEqual = CompareHouseCategory(
        houseCategory,
        house.Category
      );
      const isHouseSizeSuitable =
        (minHouseSize === null && maxHouseSize === null) ||
        (minHouseSize === null && maxHouseSize && maxHouseSize > house.Size) ||
        (minHouseSize &&
          minHouseSize <= house.Size &&
          maxHouseSize &&
          maxHouseSize >= house.Size);

      if (isCategoryEqual && isHouseSizeSuitable) {
        return house;
      } else return null;
    });

    // Update house list
    setFilteredHouses(tempFilteredHouses);
  }, [minHouseSize, maxHouseSize, houseCategory, allHouses]);

  // Render whole house list section (slider) only if there is at least 1 house
  return filteredHouses.length === 0 ? null : (
    <div>
      <Section>
        <div tw='flex items-center justify-between my-8'>
          <Title>{translate(houseCategory)}</Title>
          <p>
            <SliderCounterIndex>
              {filteredHouses.length === 0 ? 0 : currentSlideIndex}
            </SliderCounterIndex>
            /{filteredHouses.length}
          </p>
        </div>
        <div className='js-slider-carousel'>
          <SliderWrapper>
            <Swiper
              {...sliderSettings}
              touchEventsTarget='wrapper'
              onSlideChange={(e) => setCurrentSlideIndex(e.activeIndex + 1)}
              onSwiper={(swiper) => setSlider(swiper)}
            >
              {filteredHouses.map((house) => (
                <SwiperSlide key={house.Name}>
                  <HouseListItem {...house} />
                </SwiperSlide>
              ))}
            </Swiper>
          </SliderWrapper>
        </div>

        <ArrowsNavigationWrapper>
          <SliderArrow onClick={() => slider?.slidePrev()}>
            <i className='fas fa-arrow-left' />
          </SliderArrow>
          <SliderArrow onClick={() => slider?.slideNext()} tw='mx-3'>
            <i className='fas fa-arrow-right' />
          </SliderArrow>
        </ArrowsNavigationWrapper>
      </Section>
      <hr tw='mt-12 border' />
    </div>
  );
};

export default HouseList;
