import tw, { css, styled } from 'twin.macro';

export const DesignsGrid = tw.div`grid grid-cols-2 gap-3 xl:(grid-cols-2)`;

export const DesignsGridItem = styled.figure<{ isActive?: boolean }>(
  ({ isActive }) => [
    css`
      img {
        width: 100%;
        display: block;
        ${tw`border-4 border-white`};
        ${isActive && tw`border-green`};
      }
    `,
    tw`cursor-pointer`,
  ]
);
