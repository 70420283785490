import 'twin.macro';
import { LinkWithQuery } from '../../../../components/LinkWithQuery';
import { Title } from '../../../../styles/components';
import SummaryOption from '../SummaryOption';

interface ISummaryGroup {
  name: string; // name of the group e.g. Fassade / Dach / Fenster und hausturen
  linkValue: string; // value of the group used for redirect e.g. facade / roof / windows-door
  config: never[];
}

const SummaryGroup: React.FC<ISummaryGroup> = ({ name, linkValue, config }) => {
  return (
    <div>
      <div tw='flex items-center justify-between'>
        <Title>{name}</Title>
        <LinkWithQuery to={`/configurator/${linkValue}`}>
          <i className='fas fa-pencil-alt' tw='text-red cursor-pointer'></i>
        </LinkWithQuery>
      </div>
      <hr tw='mt-2' />

      {config?.map((option, index) => (
        <SummaryOption key={index} option={option} linkValue={linkValue} />
      ))}
    </div>
  );
};

export default SummaryGroup;
