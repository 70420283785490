import tw, { css, styled } from 'twin.macro';

export const CompanionPanelAccordionWrapper = tw.div`mt-2`;

export const CompanionPanelAccordionHeader = tw.header`flex space-x-3 items-center`;

export const CompanionPanelAccordionBody = styled.div(() => [
  css`
    margin-top: 10px;
    .ReactCollapse--collapse {
      transition: height 300ms ease;
    }
  `,
]);

export const CompanionPanelAccordionListItem = tw.li`flex uppercase items-center`;

export const CompanionClientAvatar = tw.img`w-12 block`;

export const CompanionPanelIcon = styled.i<{ isActive?: boolean }>(
  ({ isActive }) => [tw`text-gray-400`, isActive && tw`text-red`]
);

export const CompanionPanelFunctionItem = styled.p<{ isActive: boolean }>(
  ({ isActive }) => [
    tw`ml-2 text-sm text-gray-300`,
    isActive &&
      tw`text-gray-800 hover:text-red transition-colors duration-200 cursor-pointer`,
  ]
);

export const CompanionRefreshButton = tw.button`ml-auto uppercase text-sm mt-4 block cursor-pointer hover:text-red transition-colors duration-200 font-medium`;
