import { Link, useLocation } from 'react-router-dom';
import {
  DesktopMenuList,
  DesktopMenuListItem,
  DesktopMenuListLink,
  DesktopMenuWrapper,
  LogoImg,
} from './styles';
import { useContext } from 'react';
import { configuratorSteps } from '../../configuratorSteps.json';
import CompanionPanelTrigger from '../../../Companion/CompanionPanel/CompanionPanelTrigger';
import { IConfiguratorStepName } from '../../../../utils/types';
import { TranslationContext } from '../../../../context/TranslationContext';
import { CanvasContext } from '../../../../context/CanvasContext';

/** Desktop navigation bar
 * @param {string} configurationStep configuration step name used for distinguising between active list items
 */

interface IDesktopMenu {
  configuratorStep: IConfiguratorStepName;
}

const DesktopMenu: React.FC<IDesktopMenu> = ({ configuratorStep }) => {
  const { search } = useLocation();
  const { translate } = useContext(TranslationContext);
  const {
    canvasState: { isMobileVendor },
  } = useContext(CanvasContext);

  const steps = isMobileVendor
    ? configuratorSteps.filter((step) => step.name !== 'Layout')
    : configuratorSteps;

  return (
    <DesktopMenuWrapper>
      <CompanionPanelTrigger isDesktop />
      <Link to='/'>
        <LogoImg src='/assets/img/logo.svg' alt='Town Country logo' />
      </Link>
      <DesktopMenuList>
        {steps.map((step) => (
          <DesktopMenuListItem key={step.value}>
            <DesktopMenuListLink
              to={`/configurator/${step.value}${search}`}
              isActive={configuratorStep === step.name}
            >
              {translate(step.name)}
            </DesktopMenuListLink>
          </DesktopMenuListItem>
        ))}
      </DesktopMenuList>
    </DesktopMenuWrapper>
  );
};

export default DesktopMenu;
