import { Divider, Section } from '../../../styles/components';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from '../../../utils/helpers';
import { TranslationContext } from '../../../context/TranslationContext';
import { CanvasContext, unityContext } from '../../../context/CanvasContext';
import tw from 'twin.macro';

/**
 * Layout - third step of the configuration process
 */

const HouseConfiguratorLayout = () => {
  const { translate } = useContext(TranslationContext);
  const {
    canvasDispatch,
    canvasState: { currentFloor, isInsideHouse, path },
  } = useContext(CanvasContext);
  const [layouts, setLayouts] = useState([]);
  const houseName = useQuery().get('houseName') ?? '';

  // Set first floor view when component is loaded, unload layout view when component is umounted
  useEffect(() => {
    if(!isInsideHouse){
      unityContext.send('Launcher', 'RequestLayoutWindows', 0);
    }
    return () => {
      unityContext.send('Launcher', 'RequestLayoutWindows', -1);
      canvasDispatch({ type: 'UPDATE_IS_INSIDE_HOUSE', payload: false });
    };
  }, [canvasDispatch]);

  useEffect(() => {
    const updateLayoutMarkers = () => {
      if (!isInsideHouse) {
        setTimeout(() => {
          unityContext.send('Launcher', 'RequestLayoutWindows', currentFloor);
        }, 250);
      }
    };

    window.addEventListener('resize', updateLayoutMarkers);

    return () => window.removeEventListener('resize', updateLayoutMarkers);
  }, [currentFloor, isInsideHouse]);

  useEffect(() => {
    async function getLayout() {
      try {
        const res = await fetch(`${path}/Json/HouseFloorSets.json`);
        const data = await res.json();
        setLayouts(data[houseName][0]?.Floors);
      } catch (err) {
        console.log(err);
      }
    }

    getLayout();
  }, [houseName]);

  // Open house layout window
  const handleRequestLayoutWindow = (layoutIndex: number) => {
    unityContext.send('Launcher', 'RequestLayoutWindows', layoutIndex);
    canvasDispatch({ type: 'UPDATE_LAYOUT_FLOOR', payload: layoutIndex });
    canvasDispatch({ type: 'UPDATE_IS_INSIDE_HOUSE', payload: false });
  };

  return (
    <Section>
      {layouts.map((layout: any, index) => (
        <div key={index}>
          <h1 tw='mb-2'>{translate(layout.Label.Source)}</h1>
          <div
            tw='w-56 h-44 border-4 border-white cursor-pointer'
            css={[currentFloor === index && tw`border-green`]}
            onClick={() => handleRequestLayoutWindow(index)}
          >
            <img
              src={`${path}/${layout.ImagePath}`}
              alt={translate(layout.Label.Source)}
              tw='w-full h-full block object-cover'
            />
          </div>
          <Divider tw='mt-6' />
        </div>
      ))}
    </Section>
  );
};

export default HouseConfiguratorLayout;
