import React, { createContext, useEffect, useReducer } from 'react';
import { unityContext } from './CanvasContext';
import { companionReducer } from './reducers/companionReducer';
import { ICompanionClient } from '../utils/types';

export interface ICompanionState {
  isAuth: boolean;
  isCompanionPanelOpen: boolean;
  isCompanionSettingsOpen: boolean;
  isCompanionLoginModalOpen: boolean;
  isSalesmanPanelEnabled: boolean;
  postCode: string;
  deviceName: string;
  followDevice: { UserId: string; ShouldFollow: boolean };
  clientsList: ICompanionClient[];
  token: string;
}

export interface IAction {
  type:
    | 'AUTH_SUCCESS'
    | 'AUTH_FAIL'
    | 'TOGGLE_PANEL'
    | 'TOGGLE_SETTINGS'
    | 'TOGGLE_LOGIN'
    | 'LOGOUT'
    | 'UPDATE_COMPANION_SETTINGS'
    | 'SET_FOLLOW_DEVICE'
    | 'UPDATE_CLIENTS_LIST';
  payload?: any;
}

export interface ICompanionContext {
  companionCtx: ICompanionState;
  companionDispatch: React.Dispatch<IAction>;
}

// Companion initial state
const companionContext = {
  isAuth: false, // Is logged in
  isCompanionLoginModalOpen: false, // Companion login modal
  isCompanionPanelOpen: false, // Companion panel with all connected devices
  isCompanionSettingsOpen: false, // Companion settings modal
  isSalesmanPanelEnabled: true, // If you want to access salesman panel - checkbox in settings
  postCode: '',
  deviceName: '',
  token: '',
  followDevice: { UserId: '', ShouldFollow: false },
  clientsList: [],
};

export const CompanionContext = createContext<ICompanionContext>(null!);

const CompanionContextProvider: React.FC = ({ children }) => {
  const [ctx, dispatch] = useReducer(companionReducer, companionContext);
  // Login to the companion with token from local storage
  useEffect(() => {
    const token = localStorage.getItem('salesmanToken');

    if (token) {
      unityContext.on('loaded', () => {
        setTimeout(() => {
          if (token) {
            unityContext.send('Launcher', 'SalesmanLoginToken', token);
            dispatch({ type: 'AUTH_SUCCESS', payload: { token } });
          }
        }, 2000); // When canvas is loaded we deley the request because it's not ready right away?
      });
    }

    // Redirect to the settigns modal if token authentication occurs, and no settings to load
    if (ctx.isCompanionLoginModalOpen && ctx.isAuth) {
      dispatch({ type: 'TOGGLE_LOGIN' });
      setTimeout(() => {
        dispatch({ type: 'TOGGLE_SETTINGS' });
      }, 200);
    }
  }, [ctx.isAuth, ctx.isCompanionLoginModalOpen]);

  return (
    <CompanionContext.Provider
      value={{ companionCtx: ctx, companionDispatch: dispatch }}
    >
      {children}
    </CompanionContext.Provider>
  );
};

export default CompanionContextProvider;
