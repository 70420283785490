import { Link, useLocation } from 'react-router-dom';

/**
 * Link returning {children} wrapped in <a href="x"> alongside with all query params
 * @param {string} to url e.g. "/home/123"
 */

interface ILinkWithQuery {
  to: string;
  scrollTo?: string;
}

export const LinkWithQuery: React.FC<ILinkWithQuery> = ({
  children,
  to,
  scrollTo,
  ...props
}) => {
  const { search } = useLocation();

  return (
    <Link to={to + search + (scrollTo == null ? "" : scrollTo)} {...props}>
      {children}
    </Link>
  );
};
