import { unityContext } from '../CanvasContext';
import { IAction, ICompanionState } from '../CompanionContext';

export const companionReducer = (state: ICompanionState, action: IAction) => {
  switch (action.type) {
    case 'AUTH_SUCCESS':
      const token = action.payload?.token;
      if (token) localStorage.setItem('salesmanToken', token);

      const isSalesmanPanelEnabled =
        localStorage.getItem('isSalesmanPanelEnabled') || true;

      const salesmanSettingsStr = localStorage.getItem('salesmanSettings');

      let updatedState = {
        ...state,
        isAuth: true,
        isSalesmanPanelEnabled,
      };

      if (salesmanSettingsStr) {
        const salesmanSettings = JSON.parse(salesmanSettingsStr);
        updatedState = {
          ...updatedState,
          ...salesmanSettings,
        };

        // Update companion settings
        unityContext.send(
          'Launcher',
          'SalesmanSetSettings',
          JSON.stringify({
            deviceName: salesmanSettings.deviceName,
            clientGroupName: salesmanSettings.postCode,
          })
        );
      }

      return updatedState;
    case 'AUTH_FAIL':
    case 'LOGOUT':
      // Clear local storage
      localStorage.removeItem('salesmanToken');
      localStorage.removeItem('salesmanSettings');
      localStorage.removeItem('isSalesmanPanelEnabled');

      return {
        isAuth: false,
        isCompanionLoginModalOpen: false,
        isCompanionPanelOpen: false,
        isCompanionSettingsOpen: false,
        isSalesmanPanelEnabled: true,
        postCode: '',
        followDevice: { UserId: '', ShouldFollow: false },
        deviceName: '',
        token: '',
        clientsList: [],
      };

    case 'UPDATE_CLIENTS_LIST':
      return {
        ...state,
        clientsList: action.payload,
      };
    case 'TOGGLE_PANEL':
      return {
        ...state,
        isCompanionPanelOpen: !state.isCompanionPanelOpen,
      };
    case 'TOGGLE_SETTINGS':
      return {
        ...state,
        isCompanionSettingsOpen: !state.isCompanionSettingsOpen,
      };
    case 'SET_FOLLOW_DEVICE':
      return {
        ...state,
        followDevice: {
          UserId: action.payload.UserId,
          ShouldFollow: action.payload.ShouldFollow,
        },
      };
    case 'TOGGLE_LOGIN':
      return {
        ...state,
        isCompanionLoginModalOpen: !state.isCompanionLoginModalOpen,
      };
    case 'UPDATE_COMPANION_SETTINGS':
      // Save salesman settings
      localStorage.setItem(
        'isSalesmanPanelEnabled',
        action.payload?.isSalesmanPanelEnabled
      );
      localStorage.setItem(
        'salesmanSettings',
        JSON.stringify({
          deviceName: action.payload?.deviceName,
          postCode: action.payload?.postCode,
        })
      );

      return {
        ...state,
        isSalesmanPanelEnabled: action.payload?.isSalesmanPanelEnabled,
        deviceName: action.payload?.deviceName,
        postCode: action.payload?.postCode,
      };
    default: {
      return state;
    }
  }
};
