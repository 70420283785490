import tw, { css, styled } from 'twin.macro';

export const AccordionWrapper = tw.div`mb-4`;

export const AccordionHeader = tw.header`flex justify-between items-center cursor-pointer font-bold`;

export const AccordionBody = styled.div(() => [
  css`
    transition: all 0.3s ease-in-out;
    .ReactCollapse--collapse {
      transition: height 300ms;
    }
  `,
]);

export const CircleIcon = styled.div<{ iconPath: string; color: string }>(
  ({ iconPath, color }) => [
    css`
      background-color: ${color};
      width: 75%;
      height: 75%;
      padding: 10px;
      display: block;
      margin: 12.5% auto;
      -webkit-mask-image: url(${iconPath});
      mask-image: url(${iconPath});
    `,
  ]
);

export const AccordionCircle = styled.div<{
  notSelected?: boolean;
  isActive?: boolean;
}>(({ isActive }) => [
  tw`w-12 h-12 rounded-full cursor-pointer border-4 shadow-bottom`,
  isActive && tw`border-green!`,
]);
