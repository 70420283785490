import { useEffect, useState } from 'react';
import { Divider, Title } from '../../../styles/components';
import {
  CloseModalButton,
  CloseModalIcon,
  ModalBox,
  ModalHeader,
  ModalWrapper,
} from './styles';
import 'twin.macro';
import { RouteComponentProps, useLocation, withRouter } from 'react-router-dom';
import { isSafari } from '../../../utils/helpers';

/**
 * Modal that is shown when user opens our website in Safari (not fully supported browser)
 */

interface ISafariWarningModal extends RouteComponentProps<any> {}

const SafariWarningModal: React.FC<ISafariWarningModal> = ({ history }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (isSafari && !sessionStorage.getItem('safariModal')) {
      setIsModalOpen(true);
      sessionStorage.setItem('safariModal', 'true');
      setTimeout(() => {
        document.body.classList.add('no-scroll');
      });
    }
  }, []);

  const toggleModal = () => {
    setIsModalOpen(false);
    document.body.classList.toggle('no-scroll');
  };

  return (
    <ModalWrapper isModalOpen={isModalOpen}>
      <ModalBox isModalOpen={isModalOpen}>
        <ModalHeader>
          <Title>Warnung</Title>
          <Divider />
          <CloseModalButton onClick={toggleModal}>
            <CloseModalIcon className='fas fa-times'></CloseModalIcon>
          </CloseModalButton>
        </ModalHeader>
        <p>
          Ihr Browser wird nicht vollständig unterstützt. Bitte verwenden Sie
          die neueste Version von Chrome, Opera, Firefox.
        </p>
      </ModalBox>
    </ModalWrapper>
  );
};

export default withRouter(SafariWarningModal);
