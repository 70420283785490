import { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ConfigurationAccordion from '../../../components/Configuration/ConfigurationAccordion';
import { CanvasContext } from '../../../context/CanvasContext';
import { Section } from '../../../styles/components';

/**
 * Windows and doors - fifth step of the configuration process
 */

const HouseConfiguratorWindowsAndDoor = () => {
  const { hash } = useLocation();
  const [activeAccordion, setActiveAccordion] = useState(hash.slice(1) || ''); // Default active accordion is the one from query params ...&Gaube#WallAccent (redirect from summary page option)
  const {
    canvasState: { modificationDataByCategory },
  } = useContext(CanvasContext);

  return (
    <Section>
      {modificationDataByCategory.WindowsAndDoors?.map(
        (modification: any, index: number) => (
          <ConfigurationAccordion
            key={index}
            configurationDisplayName={modification?.Display?.Label?.Source}
            configurationName={modification?.Name}
            configurationOptions={modification?.Elements}
            activeAccordion={activeAccordion}
            setActiveAccordion={setActiveAccordion}
          />
        )
      )}
    </Section>
  );
};

export default HouseConfiguratorWindowsAndDoor;
