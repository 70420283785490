import {
  AccordionCircle,
  CircleIcon,
} from '../../../../components/Configuration/ConfigurationAccordion/styles';
import { useLocation } from 'react-router';
import { useContext, useEffect, useState } from 'react';
import { getCanvasModificationsFromQueryString } from '../../../../utils/helpers';
import { css } from 'twin.macro';
import { TranslationContext } from '../../../../context/TranslationContext';
import { CanvasContext } from '../../../../context/CanvasContext';
import { LinkWithQuery } from '../../../../components/LinkWithQuery';

interface ISummaryOption {
  option: any;
  linkValue: string; // value of the group used for redirect e.g. facade / roof / windows-door
}

const SummaryOption: React.FC<ISummaryOption> = ({ option, linkValue }) => {
  const { translate } = useContext(TranslationContext);
  const {
    canvasState: { enabledModifications, path },
  } = useContext(CanvasContext);
  const configurationOptions = option.Elements;
  const { search } = useLocation();
  const [activeCircleIndex, setActiveCircleIndex] = useState(0);
  const [isModificationEnabled, setIsModificationEnabled] = useState(false);
  const activeOption = configurationOptions[activeCircleIndex];

  // Check if modification is enabled
  useEffect(() => {
    const { IsEnabled } = enabledModifications.ConfigGroups[option.Name];
    setIsModificationEnabled(IsEnabled);
  }, [option.Name, enabledModifications.ConfigGroups]);

  //  Check if query params includes modification with such option
  //  with the same group if so I want to show it instead
  useEffect(() => {
    const modifications = getCanvasModificationsFromQueryString(search);

    configurationOptions.forEach((option: any, index: any) => {
      modifications.forEach((modification) => {
        if (
          modification.group === option.Id.Group &&
          modification.id === option.Id.Id
        ) {
          setActiveCircleIndex(index);
        }
      });
    });
  }, [configurationOptions, search]);

  return isModificationEnabled ? (
    <div>
      <h1 tw='font-bold mt-3 mb-2'>
        {translate(option?.Display.Label.Source)}
      </h1>
      <LinkWithQuery
        to={`/configurator/${linkValue}`}
        scrollTo={`#${option.Name}`}
      >
        <div tw='flex items-center mb-4'>
          <AccordionCircle
            css={[
              css`
                border-color: #fff;
              `,
              !activeOption.Display.Icon &&
                css`
                  background: ${activeOption?.Display?.Tint};
                  border-color: ${activeOption?.Display?.Tint};
                `,
            ]}
          >
            {activeOption.Display.Icon && (
              <CircleIcon
                iconPath={`${path}/${activeOption.Display.Icon}`}
                color={activeOption.Display.Tint}
              />
            )}
          </AccordionCircle>
          <span tw='ml-4'>{translate(activeOption?.Display.Label.Source)}</span>
        </div>
      </LinkWithQuery>
      <hr tw='mt-1 mb-4' />
    </div>
  ) : null;
};

export default SummaryOption;
