import { useEffect, useState } from 'react';
import { getMobileOperatingSystem } from '../../utils/helpers';
import { ModalWrapper, ShopImg } from './styles';
import 'twin.macro';

interface IDownloadMobileAppButton {
  showExtendedAppText?: boolean;
}
const DownloadMobileAppButton: React.FC<IDownloadMobileAppButton> = ({
  showExtendedAppText,
}) => {
  const [system, setSystem] = useState('');

  useEffect(() => {
    const system = getMobileOperatingSystem();
    setSystem(system);
  }, []);

  if (system !== 'iOS' && system !== 'Android') return null;
  return (
    <ModalWrapper>
      {showExtendedAppText && <h1 tw='mb-3 text-sm'>Extended app</h1>}
      {system === 'iOS' ? (
        <div>
          <a href='https://www.apple.com/' target='_blank' rel='noreferrer'>
            <ShopImg
              src='/assets/icons/App_Store_Icon.svg'
              alt='Download from App store'
            />
          </a>
        </div>
      ) : (
        <div>
          <a
            href='https://play.google.com/store'
            target='_blank'
            rel='noreferrer'
          >
            <ShopImg
              src='/assets/icons/Google_play_Icon.svg'
              alt='Download from App store'
            />
          </a>
        </div>
      )}
    </ModalWrapper>
  );
};

export default DownloadMobileAppButton;
