import {
  MobileSideMenuList,
  StyledMobileSideMenu,
  MobileSideMenuListItem,
  MobileSideMenuListItemLink,
} from './styles';
import 'twin.macro';
import { useContext } from 'react';
import { configuratorSteps } from '../../configuratorSteps.json';
import { useLocation } from 'react-router';
import { IConfiguratorStepName } from '../../../../utils/types';
import { TranslationContext } from '../../../../context/TranslationContext';
import DownloadMobileAppButton from '../../../DownloadMobileAppButton';
import { Divider } from '../../../../styles/components';
import { CanvasContext } from '../../../../context/CanvasContext';

/** Mobile side menu
 * @param {string} configurationStep configuration step name used for distinguising between active list items
 * @param {function} toggleMobileMenu function that allows you to toggle mobile menu
 * @param {boolean} isMobileMenuOpen indicates whether mobile menu is open or not
 */

interface IMobileSideMenu {
  isMobileMenuOpen: boolean;
  configuratorStep: IConfiguratorStepName;
  toggleMobileMenu: () => void;
}

const MobileSideMenu: React.FC<IMobileSideMenu> = ({
  isMobileMenuOpen,
  configuratorStep,
  toggleMobileMenu,
}) => {
  const { search } = useLocation();
  const { translate } = useContext(TranslationContext);
  const {
    canvasState: { isMobileVendor },
  } = useContext(CanvasContext);

  const steps = isMobileVendor
    ? configuratorSteps.filter((step) => step.name !== 'Layout')
    : configuratorSteps;

  return (
    <StyledMobileSideMenu isOpen={isMobileMenuOpen}>
      <p tw='p-4 text-sm'>{translate('Steps')}</p>
      <MobileSideMenuList>
        {steps.map((step) => (
          <MobileSideMenuListItem key={step.value}>
            <MobileSideMenuListItemLink
              to={`/configurator/${step.value}${search}`}
              isActive={configuratorStep === step.name}
              onClick={toggleMobileMenu}
            >
              {translate(step.name)}
            </MobileSideMenuListItemLink>
          </MobileSideMenuListItem>
        ))}
      </MobileSideMenuList>
      <Divider />
      <DownloadMobileAppButton showExtendedAppText />
    </StyledMobileSideMenu>
  );
};

export default MobileSideMenu;
