import tw, { styled } from 'twin.macro';

/**
 * Button component for global usage
 * @param {boolean} isLight - optional - gives button a white background
 * @param {boolean} isPlainText - optional - returns button without border or background
 * @param {boolean} isCircle - optional - returns button shaped as a circle
 */

interface IButton {
  isLight?: boolean;
  isPlainText?: boolean;
  isCircle?: boolean;
  isDisabled?: boolean;
}
export const Button = styled.button<IButton>(
  ({ isLight, isPlainText, isCircle, isDisabled }) => [
    tw`bg-red hover:bg-darkRed transition-colors block text-white uppercase px-12 font-bold text-xl rounded-lg shadow-xl py-3`,
    isLight && tw`bg-white hover:bg-gray-100 text-black`,
    isCircle &&
      tw`bg-white hover:bg-gray-100 border-4 rounded-full p-0 w-12 h-12 flex items-center justify-center text-red`,
    isPlainText &&
      tw`bg-transparent hover:bg-transparent text-black shadow-none text-base`,
    isDisabled && tw`bg-gray-400 hover:bg-gray-400 cursor-auto`,
  ]
);

/**
 * Title component for section names
 */
export const Title = tw.h1`text-red font-bold text-3xl`;

/**
 * Section component (wrapper) - prevents inner content from exceeding it's size
 */
export const Section = tw.section`max-w-6xl mx-auto px-4`;

/**
 * Global divider (spacer)
 */
export const Divider = tw.hr`my-2`;
