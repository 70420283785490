import { ICanvasState } from '../CanvasContext';

export type CanvasActions =
  | 'UPDATE_LAYOUT_FLOOR'
  | 'CANVAS_LOADED'
  | 'UPDATE_IS_INSIDE_HOUSE'
  | 'WEBGL_LOADED'
  | 'UPDATE_ACTIVE_INDEXES'
  | 'UPDATE_MODIFICATION_DATA_BY_CATEGORY'
  | 'UPDATE_ENABLED_MODIFICATIONS'
  | 'UPDATE_ACTIVE_CONFIG_INDEX'
  | 'UPDATE_MODIFICATION_FILTERS'
  | 'CANVAS_INITIALIZED'
  | 'SET_CONFIGURATOR_STEPS'
  | 'SET_CONFIGURATOR_ACTIVE_STEP';

export interface ICanvasAction {
  type: CanvasActions;
  payload?: any;
}

export const canvasReducer = (state: ICanvasState, action: ICanvasAction) => {
  switch (action.type) {
    case 'SET_CONFIGURATOR_STEPS':
      return {
        ...state,
        configuratorSteps: action.payload,
      };
    case 'SET_CONFIGURATOR_ACTIVE_STEP':
      return {
        ...state,
        activeConfiguratorStep: action.payload,
      };

    case 'UPDATE_LAYOUT_FLOOR':
      return {
        ...state,
        currentFloor: action.payload,
      };
    case 'CANVAS_LOADED':
      return {
        ...state,
        isCanvasLoaded: true,
      };
    case 'WEBGL_LOADED':
      return {
        ...state,
        gl: action.payload,
      };
    case 'UPDATE_IS_INSIDE_HOUSE':
      return {
        ...state,
        isInsideHouse: action.payload,
      };
    case 'UPDATE_ACTIVE_CONFIG_INDEX':
      return {
        ...state,
        activeConfigIndex: action.payload,
      };
    case 'UPDATE_MODIFICATION_DATA_BY_CATEGORY':
      return {
        ...state,
        modificationDataByCategory: action.payload,
      };
    case 'UPDATE_ACTIVE_INDEXES':
      return {
        ...state,
        activeIndexes: {
          ...state.activeIndexes,
          [action.payload.modificationName]: {
            ...state.activeIndexes[action.payload.modificationName],
            [action.payload.name]: action.payload.index,
          },
        },
      };
    case 'UPDATE_ENABLED_MODIFICATIONS':
      return {
        ...state,
        enabledModifications: action.payload,
      };
    case 'UPDATE_MODIFICATION_FILTERS':
      return {
        ...state,
        filteredModifications: action.payload,
      };
    case 'CANVAS_INITIALIZED':
      return {
        ...state,
        canvasHouseInitialized: action.payload,
      };
    default: {
      return state;
    }
  }
};
