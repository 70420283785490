import { Route, RouteComponentProps, Switch, useLocation } from 'react-router';
import ConfigurationPageLayout from '../../components/Configuration/ConfigurationPageLayout';
import HouseConfiguratorFacade from './facade';
import HouseConfiguratorHome from './house';
import HouseConfiguratorLayout from './layout';
import OrderForm from './order';
import HouseConfiguratorRoof from './roof';
import HouseConfiguratorSummary from './summary';
import HouseConfiguratorWindowsAndDoor from './windowsAndDoor';
import { configuratorSteps } from '../../components/Configuration/configuratorSteps.json';
import { useContext, useEffect } from 'react';
import { CanvasContext, unityContext } from '../../context/CanvasContext';
import { useQuery } from '../../utils/helpers';
import { withRouter } from 'react-router-dom';

/**
 * 1. Routing for configurator
 * 2. Configuration page layout component (wrapper) used for every configuration step
 */

interface IConfigurator extends RouteComponentProps<any> {}

const Configurator: React.FC<IConfigurator> = ({ history }) => {
  const { pathname } = useLocation();
  const { canvasState: {path}, canvasDispatch } = useContext(CanvasContext);
  const houseName = useQuery().get('houseName');

  // Get current configuration step name based on the URL path, and JSON file with all available steps
  useEffect(() => {
    const configuratorStep: string | undefined = configuratorSteps.find(
      (step) => pathname.includes(step.value)
    )?.name;

    if (configuratorStep)
      canvasDispatch({
        type: 'SET_CONFIGURATOR_ACTIVE_STEP',
        payload: configuratorStep,
      });
  }, [pathname, canvasDispatch]);

  // Update context - get data from local json files and store them in context
  useEffect(() => {
    async function getModificationsData() {
      try {
        const res = await fetch(`${path}/Json/ModificationDataByCategory.json`);
        const data = await res.json();
        canvasDispatch({
          type: 'UPDATE_MODIFICATION_DATA_BY_CATEGORY',
          payload: data,
        });
      } catch (err) {
        console.log(err);
      }
    }

    getModificationsData();
  }, [canvasDispatch]);

  // Update context - get enabled modifications for current house
  useEffect(() => {
    async function getEnabledModifications() {
      try {
        const res = await fetch(`${path}/Json/Houses/${houseName}.json`);
        const data = await res.json();
        canvasDispatch({
          type: 'UPDATE_ENABLED_MODIFICATIONS',
          payload: data,
        });
      } catch (err) {
        console.log(err);
      }
    }

    if (houseName) getEnabledModifications();
  }, [houseName, canvasDispatch]);

  // Change
  useEffect(() => {
    unityContext.on('OnChangeUiTab', (tab) => {
      if (configuratorSteps[tab].value === 'layout') {
        canvasDispatch({ type: 'UPDATE_IS_INSIDE_HOUSE', payload: true });
        unityContext.send('Launcher', 'RequestLayoutWindows', 0);
      }
      history.push(
        `/configurator/${
          configuratorSteps[tab].value + history.location.search
        }`
      );
    });
  }, [canvasDispatch, history]);

  return (
    <ConfigurationPageLayout>
      <Switch>
        <Route path='/configurator/house' component={HouseConfiguratorHome} />
        <Route
          path='/configurator/layout'
          component={HouseConfiguratorLayout}
        />
        <Route
          path='/configurator/facade'
          component={HouseConfiguratorFacade}
        />
        <Route path='/configurator/roof' component={HouseConfiguratorRoof} />
        <Route
          path='/configurator/windows-door'
          component={HouseConfiguratorWindowsAndDoor}
        />
        <Route path='/configurator/summary/order' component={OrderForm} />
        <Route
          path='/configurator/summary'
          component={HouseConfiguratorSummary}
        />
      </Switch>
    </ConfigurationPageLayout>
  );
};

export default withRouter(Configurator);
