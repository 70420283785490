import { useLocation } from 'react-router-dom';
import { History } from 'history';
import qs from 'qs';

// A custom hook that builds on useLocation to parse
// the query string for you.
export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function CompareHouseCategory(a: string, b: string) {
  if (
    a === 'Alle' ||
    (a === 'Hausserie L' && b === 'HouseSeriesL') ||
    (a === 'Hausserie XL/XXL' && b === 'HouseSeriesXL_XXL') ||
    (a === 'Double and Terraced Houses' && b === 'DoubleAndTerracedHouses') ||
    (a === 'Bungalows' && b === 'Bungalows') ||
    (a === 'Stadtvillen' && b === 'UrbanVillas') ||
    (a === 'Einfamilienhäuser' && b === 'HouseSeries')
  ) {
    return true;
  } else return false;
}

export function getCanvasModificationsFromQueryString(queryString: string) {
  const modifications = qs.parse(queryString, { ignoreQueryPrefix: true });

  const modificationsArray: any[] = [];

  Object.keys(modifications).forEach((key) => {
    if (key === 'houseName' || key === 'size' || key === 'rooms') return null;
    modificationsArray.push({
      group: key,
      id: modifications[key],
    });
  });

  return modificationsArray;
}

export function modificationsToQueryString(modifications: []) {
  let queryString = ``;

  modifications.forEach(({ Group, Id }: any) => {
    queryString += `&${Group}=${Id}`;
  });

  return queryString;
}

export function queryStringToModifications(queryString: string): {
  [key: string]: string;
} {
  function getQueryVariable(variable: string): { [key: string]: string } {
    let decodedUrl: { [key: string]: string } = {};
    let query = window.location.search.substring(1);
    let vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split('=');
      decodedUrl[pair[0]] = pair[1];
    }
    return decodedUrl;
  }
  return getQueryVariable(queryString);
}

export function updateQueryParams(
  history: History,
  pathname: string,
  queryString: string
) {
  history.push({
    pathname,
    search: queryString,
  });
}

export function createImageFromTexture(
  gl: any,
  texture: any,
  width: number,
  height: number
) {
  // Create a framebuffer backed by the texture
  var framebuffer = gl.createFramebuffer();
  gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
  gl.framebufferTexture2D(
    gl.FRAMEBUFFER,
    gl.COLOR_ATTACHMENT0,
    gl.TEXTURE_2D,
    texture,
    0
  );

  // Read the contents of the framebuffer
  var data = new Uint8Array(width * height * 4);
  gl.readPixels(0, 0, width, height, gl.RGBA, gl.UNSIGNED_BYTE, data);

  gl.deleteFramebuffer(framebuffer);

  // Create a 2D canvas to store the result
  var canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  var context = canvas.getContext('2d');

  // Copy the pixels to a 2D canvas
  var imageData: any = context?.createImageData(width, height);
  imageData.data.set(data);
  context?.putImageData(imageData, 0, 0);

  context?.rotate((180 * Math.PI) / 180);
  context?.translate(-width, -height);

  // context?.translate(width - width / 2, 0);
  context?.drawImage(canvas, 0, 0);

  var img = new Image();
  img.src = canvas.toDataURL();
  return img;
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function validateFacadeModifications(
  modificationName: string,
  newModifications: any,
  modificationValue: string
) {
  const hasValue = modificationValue !== 'ClearElement';
  if (modificationName === 'WallMain' && hasValue) {
    newModifications.WallFrame = 'ClearElement';
    newModifications.WallClinker = 'ClearElement';
  } else if (modificationName === 'WallAccent' && hasValue) {
    newModifications.WallClinker = 'ClearElement';
    newModifications.WallFrame = 'ClearElement';
    newModifications.WallGaube = 'ClearElement';
  } else if (modificationName === 'WallFrame' && hasValue) {
    newModifications.WallClinker = 'ClearElement';
    newModifications.WallAccent = 'ClearElement';
    newModifications.WallStructure = 'ClearElement';
    newModifications.WallGaube = 'ClearElement';
  } else if (modificationName === 'WallStructure' && hasValue) {
    newModifications.WallClinker = 'ClearElement';
    newModifications.WallFrame = 'ClearElement';
  } else if (modificationName === 'WallClinker' && hasValue) {
    newModifications.WallAccent = 'ClearElement';
    newModifications.WallFrame = 'ClearElement';
    newModifications.WallStructure = 'ClearElement';
    newModifications.WallGaube = 'ClearElement';
  } else if (modificationName === 'WallGaube') {
    newModifications.WallAccent = 'ClearElement';
    newModifications.WallFrame = 'ClearElement';
    newModifications.WallClinker = 'ClearElement';
  }
  return newModifications;
}

declare const window: any;
export function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
}

export const getCurrentDate = () => {
  const now = new Date();

  let year = now.getFullYear();
  let day = now.getDate().toString();
  let month = (now.getMonth() + 1).toString();

  if (Number(day) < 10) day = `0${day}`;
  if (Number(month) < 10) month = `0${month}`;

  return `${year}-${month}-${day}`;
};

export const isSafari =
  navigator.vendor &&
  navigator.vendor.indexOf('Apple') > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf('CriOS') === -1 &&
  navigator.userAgent.indexOf('FxiOS') === -1;

export const openInNewTab = (url: string) => {
  window.open(url, '_blank').focus();
};

Storage.prototype.tryRemoveItem = function (key: string) {
  try {
    this.removeItem(key);
  } catch (e) {
    console.log(e);
  }
};