import tw, { css, styled } from 'twin.macro';

export const ModalWrapper = styled.div<{ isOpen: boolean }>(({ isOpen }) => [
  tw`bg-gray-200 border-t border-2 fixed text-xl w-full px-4 py-6 flex justify-center items-center`,
  css`
    bottom: -200px;
    transition: bottom 1s ease-in-out;
    z-index: 101;
  `,
  isOpen && tw`bottom-0`,
]);

export const ShopImg = tw.img`block w-36 sm:w-48`;
