import tw, { styled } from 'twin.macro';

// Div wrapping slider to add global styles
export const SliderWrapper = styled.div`
  padding-bottom: 24px;

  .swiper-wrapper {
    padding-bottom: 60px;
  }

  .swiper-pagination-bullets {
    bottom: 0px;
  }

  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
  }

  .swiper-pagination-bullet-active {
    ${tw`bg-red`}
  }

  @media screen and (max-width: 1023px) {
    .swiper-pagination.swiper-pagination-bullets {
      display: none;
    }
  }
`;

export const SliderCounterIndex = tw.span`font-bold text-xl pl-4`;

// Slider arrows for navigation
export const SliderArrow = tw.button`border-4 transition hover:scale-105 cursor-pointer rounded-full w-10 h-10 flex items-center justify-center text-red`;

export const ArrowsNavigationWrapper = tw.div`ml-auto justify-end flex -mt-14 relative lg:w-1/4 z-10`;
