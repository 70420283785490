import React, { createContext } from 'react';
import German from './translations/German.json';

/**
 * Translation context used for dynamic translating text from English to German
 */

export interface ITranslationContext {
  translate: (param: string) => string;
}

const translate = (param: string) => {
  //Param only first letter uppercase
  const translations: any = German;
  const translation = translations[param];

  if (!translation) {
    return param;
  } else return translation;
};
// Companion initial state
const translationContext = { translate };

export const TranslationContext = createContext<ITranslationContext>(
  undefined!
);

const CanvasContextProvider: React.FC = ({ children }) => {
  return (
    <TranslationContext.Provider value={translationContext}>
      {children}
    </TranslationContext.Provider>
  );
};

export default CanvasContextProvider;
