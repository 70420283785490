import tw, { styled } from 'twin.macro';

export const StyledNav = styled.nav`
  ${tw`py-4 px-4 fixed border-b xl:py-0`}
  width: 100%;
  z-index: 20;
  background: white;
`;

export const StepTitle = tw.h1`mx-auto font-semibold text-center`;

export const HamburgerButton = tw.i`w-5 px-4 flex cursor-pointer justify-center`;
