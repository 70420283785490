import { Link } from 'react-router-dom';
import styled from 'styled-components';
import tw, { css } from 'twin.macro';

export const StyledMobileSideMenu = styled.div<{ isOpen: boolean }>(
  ({ isOpen }) => [
    tw`absolute top-full border-t bg-white -left-full w-full border-r-2 transform xl:hidden h-screen`,
    isOpen && tw`left-0`,
    css`
      transition: left 0.3s ease-in-out;
      z-index: 200;
    `,
  ]
);

export const MobileSideMenuList = tw.ul``;

export const MobileSideMenuListItem = tw.li`border-l`;

export const MobileSideMenuListItemLink = styled(Link)<{ isActive?: boolean }>(
  ({ isActive }) => [
    tw`py-2 border-l-4 border-white pl-4 block font-semibold uppercase`,
    isActive && tw`border-red`,
  ]
);
