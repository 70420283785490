import { useEffect, useState } from 'react';
import { Section, Title } from '../styles/components';
import 'twin.macro';
import HouseList from '../components/HouseList/HouseList';
import HouseListSearch from '../components/HouseList/HouseListSearch';
import { houseCategories as availableHouseCategories } from '../components/HouseList/HouseListSearch/houseCategories.json';

/**
 * Component - home page of the website with all listed houses
 */

const houseCategories = availableHouseCategories.slice(1); // Slice out "Alle"

export type HouseCategories = typeof houseCategories[number];

const HouseListPage = () => {
  const [category, setCategory] = useState<HouseCategories>('All');
  const [minHouseSize, setMinHouseSize] = useState<number | null>(null);
  const [maxHouseSize, setMaxHouseSize] = useState<number | null>(null);
  const [activeSlidersCount, setActiveSlidersCount] = useState(1);

  // Keep track of active sliders (if no active slider then show proper message)
  useEffect(() => {
    setTimeout(() => {
      const activeSliders = document.querySelectorAll(
        '.js-slider-carousel'
      ).length;
      setActiveSlidersCount(activeSliders);
    }, 500);
  }, [category, minHouseSize, maxHouseSize]);

  return (
    <div>
      <HouseListSearch
        setCategory={setCategory}
        category={category}
        setMinHouseSize={setMinHouseSize}
        setMaxHouseSize={setMaxHouseSize}
      />
      <div style={{ height: '100px' }}></div>
      {category === 'All' ? (
        houseCategories.map((category) => (
          <HouseList
            houseCategory={category}
            key={category}
            minHouseSize={minHouseSize}
            maxHouseSize={maxHouseSize}
          />
        ))
      ) : (
        <HouseList
          houseCategory={category}
          key={category}
          minHouseSize={minHouseSize}
          maxHouseSize={maxHouseSize}
        />
      )}

      {/* Show proper message if no matching results */}
      <Section>
        {activeSlidersCount === 0 && (
          <Title tw='mt-5'>
            Es gibt keine passenden Häuser für die Auswahl.
          </Title>
        )}
      </Section>
    </div>
  );
};

export default HouseListPage;
