import { BrowserRouter as Router, Route } from 'react-router-dom';
import CompanionLoginModal from './components/Companion/Modals/CompanionLoginModal';
import CompanionPanel from './components/Companion/CompanionPanel';
import HouseListPage from './pages/HouseListPage';
import Configurator from './pages/[house_id]';
import ScrollToTop from './components/scrollToTop'; // Scroll to the top on every route change - prevents some scroll issues (react-router)
import CompanionSettingsModal from './components/Companion/Modals/CompanionSettingsModal';
import DownloadMobileAppModal from './components/DownloadMobileAppModal';
import { ReactElement, useContext, useEffect } from 'react';
import {
  AuthorizationRequest,
  RefreshAuthorizationToken,
} from './pages/[house_id]/order/api';
import { CompanionContext } from './context/CompanionContext';
import InquiryConfirmationModal from './components/Companion/Modals/InquiryConfirmationModal';
import SafariWarningModal from './components/Companion/Modals/SafariWarningModal';

function App() {
  const {
    companionCtx: { isCompanionPanelOpen },
  } = useContext(CompanionContext);
  // Login request, or refresh token
  useEffect(() => {
    AuthorizationRequest(); // Login request
    RefreshAuthorizationToken(); // Login request
  }, []);

  return (
    <>
     <Router>
        <ScrollToTop />
        {/* We are rendering configurator on the "/" route to connect to the companion through canvas (the canvas is hidden) */}
        {/* We are showing houselistpage on the /login and detecting /login page in the login modal to show the modal */}
        <Route exact path={['/', '/login']}>
          <HouseListPage />
        </Route>
        <Route path={['/configurator']}>
        </Route>
        {/* Modals */}
        <SafariWarningModal />
        <InquiryConfirmationModal />
        {/* Companion functionalities */}
        <CompanionLoginModal />
        <CompanionSettingsModal />
        {isCompanionPanelOpen ? <CompanionPanel /> : <></>}
        <Configurator />
      </Router>
      <DownloadMobileAppModal />
    </>
  );
}

export default App;
