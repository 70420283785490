import { Dispatch, SetStateAction, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import 'twin.macro';
import { TranslationContext } from '../../../context/TranslationContext';
import {
  ShareCloseButton,
  ShareIconText,
  ShareLink,
  ShareList,
  ShareListItem,
  ShareListItemCircleWrapper,
  ShareModal,
} from './styles';

/**
 * Share button component for sharing your house configuration
 */

interface IShareButton {
  setIsShareModalOpen: Dispatch<SetStateAction<boolean>>;
  isShareModalOpen: boolean;
  shareURL?: string;
}

const ShareButton: React.FC<IShareButton> = ({
  setIsShareModalOpen,
  isShareModalOpen,
  shareURL,
}) => {
  const { translate } = useContext(TranslationContext);
  const { search } = useLocation();

  function toggleShowModal(e: React.MouseEvent) {
    e.preventDefault();
    setIsShareModalOpen(!isShareModalOpen);
  }

  // get share url from props (if provided - has to be if you are sharing house from home page)
  const getShareHouseURL = () => {
    if (shareURL) return shareURL;
    const buildShareURL = `${window.location.protocol}//${window.location.host}/configurator/house${search}`;
    return buildShareURL;
  };

  return (
    <div>
      <button
        tw='border-4 rounded-full p-3 hover:bg-gray-100 transition-colors'
        onClick={toggleShowModal}
      >
        <img src='/assets/icons/share.svg' tw='block w-full' alt='Share' />
      </button>

      {isShareModalOpen && (
        <ShareModal>
          <div>
            <div tw='flex items-center justify-between mb-3'>
              <h1 tw='font-bold text-base'>{translate('House Share')}</h1>
              <ShareCloseButton onClick={toggleShowModal}>
                <i
                  className='fas fa-times'
                  tw='text-xl hover:text-red transition-colors'
                />
              </ShareCloseButton>
            </div>
            <ShareList>
              <ShareListItem>
                <ShareLink
                  href={`https://www.facebook.com/sharer.php?u=${encodeURIComponent(
                    getShareHouseURL()
                  )}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  <ShareListItemCircleWrapper style={{ background: '#4267B2' }}>
                    <i className='fab fa-facebook-f' />
                  </ShareListItemCircleWrapper>
                  <ShareIconText>{translate('Facebook')}</ShareIconText>
                </ShareLink>
              </ShareListItem>
              <ShareListItem>
                <ShareLink
                  href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                    getShareHouseURL()
                  )}&text=TownCountryHouse`}
                  target='_blank'
                  rel='noreferrer'
                >
                  <ShareListItemCircleWrapper style={{ background: '#1DA1F2' }}>
                    <i className='fab fa-twitter' />
                  </ShareListItemCircleWrapper>
                  <ShareIconText>{translate('Twitter')}</ShareIconText>
                </ShareLink>
              </ShareListItem>
              <ShareListItem>
                <ShareLink
                  href={`https://reddit.com/submit?url=${encodeURIComponent(
                    getShareHouseURL()
                  )}&title=TownCountryHouse`}
                  target='_blank'
                  rel='noreferrer'
                >
                  <ShareListItemCircleWrapper style={{ background: '#FF5700' }}>
                    <i className='fab fa-reddit' />
                  </ShareListItemCircleWrapper>
                  <ShareIconText>{translate('Reddit')}</ShareIconText>
                </ShareLink>
              </ShareListItem>
              <ShareListItem>
                <ShareLink
                  href={`http://pinterest.com/pin/create/link/?url=${encodeURIComponent(
                    getShareHouseURL()
                  )}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  <ShareListItemCircleWrapper style={{ background: '#E60023' }}>
                    <i className='fab fa-pinterest' />
                  </ShareListItemCircleWrapper>
                  <ShareIconText>{translate('Pinterest')}</ShareIconText>
                </ShareLink>
              </ShareListItem>
              <ShareListItem>
                <ShareLink
                  href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                    getShareHouseURL()
                  )}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  <ShareListItemCircleWrapper style={{ background: '#0e76a8' }}>
                    <i className='fab fa-linkedin' />
                  </ShareListItemCircleWrapper>
                  <ShareIconText>{translate('Linkedin')}</ShareIconText>
                </ShareLink>
              </ShareListItem>
              <ShareListItem>
                <ShareLink
                  as='button'
                  onClick={() => {
                    navigator.clipboard.writeText(getShareHouseURL());
                    setIsShareModalOpen(false);
                  }}
                >
                  <ShareListItemCircleWrapper style={{ background: '#808080' }}>
                    <i className='fas fa-link' />
                  </ShareListItemCircleWrapper>
                  <ShareIconText>{translate('Copy Link')}</ShareIconText>
                </ShareLink>
              </ShareListItem>
              <ShareListItem>
                <ShareLink
                /*
Hallo,
Ich wollte dieses schöne Haus teilen, ich habe es einfach mit dem Town & Country Haus Konfigurator konfiguriert.

Haus-URL: ${encodeURIComponent(getShareHouseURL())}<\a>
                */
                  href={`mailto:kontakt@tc.de?subject=Ich habe mein Traumhaus konfiguriert. Schau es Dir mal an.&body=Hallo%2C%0AIch%20wollte%20dieses%20sch%C3%B6ne%20Haus%20teilen%2C%20ich%20habe%20es%20einfach%20mit%20dem%20Town%20%26%20Country%20Haus%20Konfigurator%20konfiguriert.%0A%0AHaus-URL%3A%20${encodeURIComponent(getShareHouseURL())}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  <ShareListItemCircleWrapper style={{ background: '#808080' }}>
                    <i className='fas fa-at' />
                  </ShareListItemCircleWrapper>
                  <ShareIconText>{translate('Email')}</ShareIconText>
                </ShareLink>
              </ShareListItem>
            </ShareList>
          </div>
        </ShareModal>
      )}
    </div>
  );
};

export default ShareButton;
