import { Link } from 'react-router-dom';

/**
 * Conditional link returning {children} wrapped in <a href="x"> when {condition} is passed
 * @param {string} to url e.g. "/home/123"
 * @param {boolean} condition condition
 * @param {string} className? optional class names (added to link)
 */

interface IConditionalLink {
  to: string;
  condition: boolean;
  className?: string;
}

export const ConditionalLink: React.FC<IConditionalLink> = ({
  children,
  to,
  condition,
  className,
}) =>
  !!condition ? (
    <Link className={className} to={to}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  );
