import { HamburgerButton, StepTitle, StyledNav } from './styles';
import 'twin.macro';
import { useContext, useState } from 'react';
import MobileSideMenu from './MobileSideMenu';
import DesktopMenu from './DesktopMenu';
import { Link } from 'react-router-dom';

import CompanionPanelTrigger from '../../Companion/CompanionPanel/CompanionPanelTrigger';
import { TranslationContext } from '../../../context/TranslationContext';
import { CanvasContext } from '../../../context/CanvasContext';

const Navbar: React.FC = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { translate } = useContext(TranslationContext);
  const {
    canvasState: { activeConfiguratorStep },
  } = useContext(CanvasContext);

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  return (
    <StyledNav>
      <div tw='flex w-full items-center xl:hidden'>
        <Link to='/' tw='w-1/6'>
          <img src='/assets/img/logo.svg' alt='Town Country logo' />
        </Link>
        <StepTitle>{translate(activeConfiguratorStep).toUpperCase()}</StepTitle>
        <div tw='flex w-1/6 justify-end'>
          <CompanionPanelTrigger />
          <HamburgerButton onClick={toggleMobileMenu}>
            {isMobileMenuOpen ? (
              <i className='fas fa-times' tw='text-2xl' />
            ) : (
              <i className='fas fa-bars' tw='text-2xl' />
            )}
          </HamburgerButton>
        </div>
      </div>

      <DesktopMenu configuratorStep={activeConfiguratorStep} />

      <MobileSideMenu
        isMobileMenuOpen={isMobileMenuOpen}
        configuratorStep={activeConfiguratorStep}
        toggleMobileMenu={toggleMobileMenu}
      />
    </StyledNav>
  );
};

export default Navbar;
