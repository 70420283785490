import tw, { css, styled } from 'twin.macro';

export const ModalWrapper = styled.div<{ isModalOpen: boolean }>(
  ({ isModalOpen }) => [
    tw`fixed flex items-center justify-center top-0 left-0 w-full h-full bg-black bg-opacity-0 transform transition-colors duration-300 scale-0`,
    isModalOpen && tw`scale-100 bg-opacity-70`,
    css`
      z-index: 200;
    `,
  ]
);

export const ModalBox = styled.div<{ isModalOpen: boolean }>(
  ({ isModalOpen }) => [
    tw`bg-white relative top-20 p-14 transform transition-all duration-300`,
    isModalOpen && tw`top-0`,
  ]
);

export const ModalContentWrapper = tw.div`max-w-xs`;

export const ModalHeader = tw.div`mb-3`;

export const CloseModalIcon = tw.i`text-2xl hover:text-red transition-colors`;

export const CloseModalButton = tw.button`absolute top-4 right-4`;

export const MicrosoftBtn = styled.a<{ disabled?: boolean }>(({ disabled }) => [
  `
  align-items: center;
  border-color: rgba(0, 0, 0, 0.2);
  border-style: solid;
  border-radius: 4px;
  border-width: 1px;
  display: flex;
  font-family: Arial;
  font-size: 15px;
  padding-right: 10px;
  padding: 10px;
  text-decoration: none;
  min-width: 226px; /* OPTIONAL */
  margin-bottom: 4px; /* OPTIONAL */
  color: #fff;
  background-color: #2672ec;
  text-decoration: none;
  transition: 0.2s;
  font-size: 1.2rem;



  &:hover,
  &:focus {
    color: #fff;
    background-color: #125acd;
  }

  &:active {
    color: #fff;
    background-color: #0f4bac;
  }
  `,
  disabled && tw`bg-[#06409f] text-[#d6d6d6] pointer-events-none select-none`,
]);
