import { useContext, useEffect, useState } from 'react';
import { CompanionContext } from '../../../context/CompanionContext';
import { Button, Divider, Title } from '../../../styles/components';
import { AvailableDevicesWrapper, CompanionPanelWrapper } from './styles';
import 'twin.macro';
import CompanionPanelAccordion from './CompanionPanelAccordion';
import { CompanionPanelSubTitle } from './CompanionPanelTrigger/styles';
import { TranslationContext } from '../../../context/TranslationContext';
import { Loader } from './Loader/styles';
import { ICompanionClient } from '../../../utils/types';
import { CanvasContext, unityContext } from '../../../context/CanvasContext';
import { CompanionRefreshButton } from './CompanionPanelAccordion/styles';
import { withRouter, useLocation, RouteComponentProps } from 'react-router-dom';
import Spinner from './Spinner';
import {
  modificationsToQueryString,
  queryStringToModifications,
} from '../../../utils/helpers';

interface ICompanionPanel extends RouteComponentProps<any> {}

const CompanionPanel: React.FC<ICompanionPanel> = ({ history }) => {
  const { translate } = useContext(TranslationContext);
  const { 
    companionCtx: { isCompanionPanelOpen, isAuth, clientsList, followDevice },
    companionDispatch,
  } = useContext(CompanionContext);
  const {
    canvasState: { isCanvasLoaded },
    canvasDispatch,
  } = useContext(CanvasContext);

  const { pathname, search } = useLocation();
  const [selfClient, setSelfClient] = useState<ICompanionClient>();
  const [isSelfConnectingToSession, setIsSelfConnectingToSession] 
    = useState(false);
  const [isSelfInSession, setIsSelfInSession] = useState(false);

  function RefreshCompanionPanel() {
    unityContext.send('Launcher', 'SalesmanRefresh');
  }

  function FindOurDeviceInClientList(){
    if (clientsList.length) {
      // Find and set our device
      setSelfClient(clientsList.find((client) => client.IsSelf));
    }
    return [clientsList];
  }

  useEffect(() => {
    if (clientsList.length) {
      // Find and set our device
      setSelfClient(clientsList.find((client) => client.IsSelf));
    }
  }, [clientsList]);

  // Listen for companion events
  useEffect(() => {
    unityContext.on('SalesmanData', (salesmanData) => {
      console.log('### Recieved SalesmanData: ' + salesmanData);
      console.log(JSON.parse(salesmanData));

      // Update companion context with clients data
      companionDispatch({
        type: 'UPDATE_CLIENTS_LIST',
        payload: JSON.parse(salesmanData)?.Clients,
      });
      const targetUserId = followDevice.UserId;
      const clients = JSON.parse(salesmanData)?.Clients;
      for(let client in clients) {
        if (clients[client].UserId === targetUserId && 
          clients[client].CanUserViewToggle === false) {
            const userId = '';
            companionDispatch({
              type: 'SET_FOLLOW_DEVICE',
              payload: { userId, ShouldFollow: false },
            });        
          }
      }
    });

    unityContext.on('SalesmanConfigurationChange', (configurationStr) => {
      console.log(
        '### Recieved SalesmanConfigurationChange: ' + configurationStr
      );
      let configuration = JSON.parse(configurationStr);
      let currentQuery = queryStringToModifications(history.location.pathname);
      if (!Object.prototype.hasOwnProperty.call(configuration, 'HouseName')) {
        configuration = {
          HouseName: null,
          ModificationIds: JSON.parse(configurationStr),
        };
        configuration.HouseName = currentQuery['houseName'];
      }

      if (
        configuration == null ||
        configuration.HouseName == null ||
        configuration.ModificationIds == null
      )
        return;

      for (
        let index = 0;
        index < configuration.ModificationIds.length;
        index++
      ) {
        const element = configuration.ModificationIds[index];
        currentQuery[element.Group] = element.Id;
      }
      let modificationArry: Array<{ Group: string; Id: string }> = [];
      for (const key in currentQuery) {
        if (Object.prototype.hasOwnProperty.call(currentQuery, key)) {
          const element = currentQuery[key];
          if (key !== 'houseName') {
            modificationArry.push({ Group: key, Id: element });
          }
        }
      }
      canvasDispatch({
        type: 'CANVAS_INITIALIZED',
        payload: configuration.HouseName,
      });
      configuration.ModificationIds = modificationArry;

      if (configuration.HouseName && configuration.ModificationIds) {
        const modificationQS = `?houseName=${
          configuration.HouseName
        }${modificationsToQueryString(configuration.ModificationIds)}`;
        console.log(`SalesmanConfigurationChange: ` + modificationQS);

        let pathname = window.location.pathname;
        if (
          !pathname.startsWith('/configurator') ||
          currentQuery['houseName'] !== configuration.HouseName
        ) {
          pathname = '/configurator/house';
        }

        //if (!search.includes(configuration.HouseName)) {
        history.push({
          pathname: pathname,
          search: modificationQS,
        });
        //}
      }
    });
  }, [companionDispatch, history, isCanvasLoaded, pathname, search]);

  // Listen for salesman connection callbacks
  useEffect(() => {
    unityContext.on(
      'OnSelfConnectingToSession',
      (isSelfConnectingToSession) => {
        setIsSelfConnectingToSession(isSelfConnectingToSession);
      }
    );

    unityContext.on('OnSelfInSession', (isSelfInSession) => {
      setIsSelfInSession(isSelfInSession);
      setIsSelfConnectingToSession(false);
    });
  }, []);
  
  // Refresh companion panel when it's open
  useEffect(() => {
    RefreshCompanionPanel();
    FindOurDeviceInClientList();
  }, [isCompanionPanelOpen]);

  const handleClosePanel = () => companionDispatch({ type: 'TOGGLE_PANEL' });

  const handleCloseSession = () => {
    console.log('SalesmanCloseSession send');
    unityContext.send('Launcher', 'SalesmanEndSession');
    setIsSelfInSession(false);
  };

  return (
    <CompanionPanelWrapper isOpen={isCompanionPanelOpen && isAuth}>
      <div tw='flex items-center justify-between'>
        <Title>{translate('Salesman panel')}</Title>
        <button tw='cursor-pointer p-2 pr-0' onClick={handleClosePanel}>
          <i
            className='fa fa-times'
            tw='text-xl hover:text-red transition-colors'
          ></i>
        </button>
      </div>

      {isSelfConnectingToSession ? (
        <Spinner />
      ) : (
        <>
          <Divider />
          <>
            <CompanionPanelSubTitle>
              {translate('Current Session')}
            </CompanionPanelSubTitle>
            {/* Our device */}

            {selfClient && (
              <CompanionPanelAccordion
                key={selfClient.UserId}
                client={selfClient}
                canAddToSession={selfClient?.IsSessionOwner || !isSelfInSession}
              />
            )}
            {/* All devices that are in session && not us */}
            {selfClient &&
              clientsList
                .filter(
                  (client) =>
                    !client.IsSelf &&
                    (client.IsAddedToSession || client.IsInSession)
                )
                ?.map((client) => (
                  <CompanionPanelAccordion
                    key={client.UserId}
                    client={client}
                    canAddToSession={
                      (selfClient?.IsSessionOwner || !isSelfInSession) && !client.IsSessionOwner
                    }
                  />
                ))}
          </>
          {/* Available devices */}
          <Divider />
          <CompanionPanelSubTitle>
            {translate('Available')}
          </CompanionPanelSubTitle>
          <AvailableDevicesWrapper>
            {/* All devices that aren't in session && not us */}
            {selfClient &&
              clientsList
                .filter(
                  (client) =>
                    !client.IsSelf &&
                    !client.IsAddedToSession &&
                    !client.IsInSession
                )
                ?.map((client) => (
                  <CompanionPanelAccordion
                    key={client.UserId}
                    client={client}
                    canAddToSession={
                      selfClient?.IsSessionOwner || !isSelfInSession
                    }
                  />
                ))}
          </AvailableDevicesWrapper>
        </>
      )}
      {selfClient?.IsSessionOwner && clientsList.filter((client) => client.IsInSession).length > 1 && (
        <Button tw='text-xl w-full mt-auto' onClick={handleCloseSession}>
          {translate('End Session')}
        </Button>
      )}
      {selfClient?.IsInSession && !selfClient?.IsSessionOwner && (
        <Button tw='text-xl w-full mt-auto' onClick={handleCloseSession}>
           {translate('Disconnect')}
        </Button>
      )}
    </CompanionPanelWrapper>
  );
};

export default withRouter(CompanionPanel);
