import Navbar from '../Navbar';
import HouseCanvas from '../HouseCanvas';
import { Section, Title } from '../../../styles/components';
import ShareButton from '../../HouseList/ShareButton';
import {
  HouseInfoList,
  HouseInfoListItem,
  HouseInfoListItemIcon,
} from '../../HouseList/HouseListItem/styles';
import { useQuery } from '../../../utils/helpers';
import { useContext, useState } from 'react';
import ConfigurationStepsNavigation from '../ConfigurationStepsNavigation';
import tw, { css } from 'twin.macro';
import { useLocation } from 'react-router';
import { TranslationContext } from '../../../context/TranslationContext';
import { CanvasContext } from '../../../context/CanvasContext';

/**
 * Configurator page layout component depending on route, it's showing either bigger canvas with modifications
 * panel on the right, or layout with smaller canvas (on /summary/...)
 * @param {boolean} [isCanvasPreviewOnly] canvas is in preview mode in /summary (Zusamenfassung)
 */

interface IConfigurationPageLayout {
  isCanvasPreviewOnly?: boolean;
}

const ConfigurationPageLayout: React.FC<IConfigurationPageLayout> = ({
  children,
}) => {
  const { translate } = useContext(TranslationContext);
  const {
    canvasState: { activeConfiguratorStep },
  } = useContext(CanvasContext);
  const { pathname } = useLocation();
  const [isSummaryShareModalOpen, setSummaryShareModalOpen] = useState(false);
  const [isConfigurationShareModalOpen, setConfigurationShareModalOpen] =
    useState(false);
  const isLoginPage = pathname.includes('login');

  const houseName = useQuery().get('houseName')?.replaceAll('_', ' ') ?? '';
  const roomsCount = useQuery().get('rooms') ?? '';
  const houseSize = useQuery().get('size') ?? '';

  const isSmallCanvas = activeConfiguratorStep === 'Summary';

  // Here we have some dynamic styles based on isSmallCanvas - in order to prevent Canvas from re-renderingon route change,
  // we have to render the <HouseCanvas/> component only once here
  return (
    <div
      css={[
        tw`xl:(h-screen)`,
        isSmallCanvas && tw`xl:(overflow-y-hidden)`,
        pathname === '/' && tw`hidden`, // Hide canvas (whole configurator) on homepage
      ]}
    >
      <Navbar />
      <div style={{ height: '70px' }}></div>
      <div
        css={[
          tw`xl:(flex) mb-5 xl:mb-0`,
          isSmallCanvas && tw`flex-row-reverse mx-auto xl:(mt-4 max-w-6xl)`,
          css`
            height: calc(100% - 161px); // 100% - (navbar + bottom navigation)
          `,
        ]}
      >
        <div
          css={[
            tw`flex-grow xl:(h-full)`,
            isSmallCanvas && tw`flex-grow-0 mx-auto xl:(w-1/3)`,
          ]}
        >
          <div
            css={[
              isSmallCanvas &&
                tw`mb-4 xl:(mb-0 rounded-md shadow-bottom h-auto) h-full`,
              !isSmallCanvas &&
                css`
                  height: 100%;
                `,
            ]}
          >
            <HouseCanvas showArrows={!isSmallCanvas} />

            {isSmallCanvas && (
              <>
                <Section tw='relative max-w-3xl hidden xl:(block max-w-none)'>
                  <hr tw='pb-4' />
                  <div tw='flex justify-between items-center'>
                    <Title>{houseName}</Title>
                    <ShareButton
                      isShareModalOpen={isSummaryShareModalOpen}
                      setIsShareModalOpen={setSummaryShareModalOpen}
                    />
                  </div>
                  <HouseInfoList tw='pb-5'>
                    <HouseInfoListItem>
                      <HouseInfoListItemIcon src='/assets/icons/pen_ruler.svg' />
                      <span>
                        {houseSize} m<sup>2</sup>
                      </span>
                    </HouseInfoListItem>
                    <HouseInfoListItem>
                      <HouseInfoListItemIcon src='/assets/icons/door.svg' />
                      <span>
                        {roomsCount} {translate('Rooms')}
                      </span>
                    </HouseInfoListItem>
                  </HouseInfoList>
                  <hr />
                  <div tw='flex justify-between py-3 font-bold'>
                    <h2>{translate('Total')}</h2>
                    <h2>{translate('Price On Demand')}</h2>
                  </div>
                </Section>
              </>
            )}
          </div>
        </div>
        <div
          css={[
            tw`xl:(max-w-md px-2 shadow-bottom rounded-lg rounded-br-none h-full pb-0) h-[40vh] md:h-[50vh] pb-16 md:pb-20 overflow-y-scroll w-full max-w-3xl mx-auto relative`,
            isSmallCanvas && tw`xl:(w-2/3 max-w-none shadow-none mr-3)`,
            css``,
          ]}
        >
          <Section>
            {isSmallCanvas && (
              <>
                <div tw='relative max-w-3xl xl:hidden'>
                  <hr tw='pb-4' />
                  <div tw='flex justify-between items-center'>
                    <Title>{houseName}</Title>
                    <ShareButton
                      isShareModalOpen={isSummaryShareModalOpen}
                      setIsShareModalOpen={setSummaryShareModalOpen}
                    />
                  </div>
                  <HouseInfoList tw='pb-5'>
                    <HouseInfoListItem>
                      <HouseInfoListItemIcon src='/assets/icons/pen_ruler.svg' />
                      <span>
                        {houseSize} m<sup>2</sup>
                      </span>
                    </HouseInfoListItem>
                    <HouseInfoListItem>
                      <HouseInfoListItemIcon src='/assets/icons/door.svg' />
                      <span>
                        {roomsCount} {translate('Rooms')}
                      </span>
                    </HouseInfoListItem>
                  </HouseInfoList>
                  <hr />
                  <div tw='flex justify-between py-3 font-bold'>
                    <h2>{translate('Total')}</h2>
                    <h2>{translate('Price On Demand')}</h2>
                  </div>

                  <hr tw='pb-4' />
                </div>
              </>
            )}
            {!isSmallCanvas && (
              <div tw=' flex justify-between items-center mt-4'>
                <Title>{houseName}</Title>
                <ShareButton
                  isShareModalOpen={isConfigurationShareModalOpen}
                  setIsShareModalOpen={setConfigurationShareModalOpen}
                />
              </div>
            )}
            {!isSmallCanvas && (
              <>
                <h2 tw='mt-2 text-xl mb-3 font-bold'>
                  {translate(activeConfiguratorStep)}
                </h2>
                <hr tw='mb-3' />
              </>
            )}
          </Section>
          {children}
        </div>
      </div>
      {!isLoginPage && <ConfigurationStepsNavigation />}
    </div>
  );
};

export default ConfigurationPageLayout;
