import { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Divider, Title } from '../../../styles/components';
import {
  CloseModalButton,
  CloseModalIcon,
  ModalBox,
  ModalHeader,
  ModalWrapper,
} from './styles';
import 'twin.macro';
import {
  CheckBox,
  FormGroup,
  Label,
} from '../../../pages/[house_id]/order/styles';
import { CompanionContext } from '../../../context/CompanionContext';
import { TranslationContext } from '../../../context/TranslationContext';
import { ModalContentWrapper } from './styles';
import { FormErrorMsg, Input } from '../../../styles/components/formComponents';
import { unityContext } from '../../../context/CanvasContext';
import { RouteComponentProps, useLocation, withRouter } from 'react-router';
import { useQuery } from '../../../utils/helpers';

/**
 * Modal for setting up the companion
 */
 interface ICompanionSettingsModal extends RouteComponentProps<any> {}


const CompanionSettingsModal: React.FC<ICompanionSettingsModal> = ({ history }) =>  {
  const { translate } = useContext(TranslationContext);
  const queryToken = useQuery().get('token');
  const { companionDispatch, companionCtx } = useContext(CompanionContext);
  const { isCompanionSettingsOpen: isModalOpen, isAuth } = companionCtx;

  const [deviceName, setDeviceName] = useState('Undefiniert');
  const [isSalesmanPanelEnabled, setIsSalesmanPanelEnabled] = useState(true);
  // Form errors
  const [deviceNameError, setDeviceNameError] = useState(false);

  // Set initial values from context
  useEffect(() => {
    const { deviceName, isSalesmanPanelEnabled } = companionCtx;
    setDeviceName(deviceName);
    setIsSalesmanPanelEnabled(isSalesmanPanelEnabled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    companionCtx.deviceName,
    companionCtx.isSalesmanPanelEnabled,
  ]);

  // Adds no scrollbar class to body when modal is open
  const toggleScrollBar = useCallback(() => {
    if (isModalOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [isModalOpen]);

  const toggleModal = useCallback(() => {
    companionDispatch({ type: 'TOGGLE_SETTINGS' });
    toggleScrollBar();
  }, [companionDispatch, toggleScrollBar]);

  // Listen for specific key combination
  useEffect(() => {
    function listenForCombination(e: KeyboardEvent) {
      if (e.key === 'F9' && e.shiftKey && e.ctrlKey && isAuth) {
        toggleModal();
      }
    }
    // Add event listener
    document.addEventListener('keyup', listenForCombination);

    // Clean up function - remove event listener
    return () => document.removeEventListener('keyup', listenForCombination);
  }, [toggleModal, isAuth]);

  // Listen for open/close modal and add/remove no-scroll
  useEffect(() => {
    toggleScrollBar();
  }, [isModalOpen, toggleScrollBar]);

  // Input on change handler
  const handleChange = ({
    target: { value, name, checked },
  }: React.ChangeEvent<HTMLInputElement>) => {
    switch (name) {
      case 'deviceName':
        setDeviceName(value);
        break;
      case 'isSalesmanPanelEnabled':
        setIsSalesmanPanelEnabled(checked);
        break;
      default:
        break;
    }
  };

  const handleLogout = () => {
    companionDispatch({ type: 'LOGOUT' });
    unityContext.send('Launcher', 'Disconnect');
    if(queryToken){
      history.push('/');
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Validation
    if (!deviceName) setDeviceNameError(true);
    else if (deviceNameError) setDeviceNameError(false);

    if (deviceName) {
      companionDispatch({
        type: 'UPDATE_COMPANION_SETTINGS',
        payload: { isSalesmanPanelEnabled, deviceName },
      });
      companionDispatch({
        type: 'TOGGLE_SETTINGS',
      });

      // Update companion settings
      unityContext.send(
        'Launcher',
        'SalesmanSetSettings',
        JSON.stringify({
          deviceName    
        })
      );

      toggleScrollBar();
    }
  };

  return (
    <ModalWrapper isModalOpen={isModalOpen && isAuth}>
      <ModalBox isModalOpen={isModalOpen && isAuth}>
        <ModalHeader>
          <Title>{translate('Companion Settings')}</Title>
          <Divider />
          <CloseModalButton onClick={toggleModal}>
            <CloseModalIcon className='fas fa-times'></CloseModalIcon>
          </CloseModalButton>
        </ModalHeader>
        <ModalContentWrapper>
          <form onSubmit={handleSubmit}>
            <FormGroup>
              <Label htmlFor='deviceName' isRequired>
                {translate('Custom Device Name')}
              </Label>
              <Input
                type='text'
                id='deviceName'
                maxLength={30}
                name='deviceName'
                hasError={deviceNameError}
                value={deviceName}
                onChange={handleChange}
              />
              <FormErrorMsg isVisible={deviceNameError}>
                {translate('Invalid input')}
              </FormErrorMsg>
            </FormGroup>
            <FormGroup>
              <div tw='flex relative cursor-pointer'>
                <CheckBox
                  type='checkbox'
                  name='isSalesmanPanelEnabled'
                  id='isSalesmanPanelEnabled'
                  tw='mt-1'
                  onChange={handleChange}
                  checked={isSalesmanPanelEnabled}
                />
                <label
                  htmlFor='isSalesmanPanelEnabled'
                  tw='pl-2 cursor-pointer'
                >
                  {translate('Enable Salesman panel')}
                </label>
              </div>
            </FormGroup>
            <Button tw='mt-4 w-full' type='submit'>
              {translate('Apply')}
            </Button>
          </form>
          <button
            tw='font-bold mt-2 ml-auto block cursor-pointer'
            onClick={handleLogout}
          >
            {translate('Log out')}
          </button>
        </ModalContentWrapper>
      </ModalBox>
    </ModalWrapper>
  );
};

export default withRouter(CompanionSettingsModal);
