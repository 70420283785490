import { createGlobalStyle } from 'styled-components';
import { GlobalStyles as BaseStyles } from 'twin.macro';

/**
 * Global styles should go below
 */
const CustomStyles = createGlobalStyle`
  html, body, #root {
    height: 100%;
    width: 100%;
  }
  html {
   scroll-behavior: smooth;
  }

  body {
    font-family: "Roboto", sans-serif;
    padding-bottom: 85px;
  }

  .no-scroll {
    overflow-y: hidden;
  }

`;

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <CustomStyles />
  </>
);

export default GlobalStyles;
