import { useContext, useEffect, useState } from 'react';
import { Collapse } from 'react-collapse';
import {
  CompanionClientAvatar,
  CompanionPanelAccordionBody,
  CompanionPanelAccordionHeader,
  CompanionPanelAccordionListItem,
  CompanionPanelAccordionWrapper,
  CompanionPanelFunctionItem,
} from './styles';
import { TranslationContext } from '../../../../context/TranslationContext';
import { unityContext } from '../../../../context/CanvasContext';
import { ICompanionClient } from '../../../../utils/types';
import tw from 'twin.macro';
import EyeSVG from './Icons/EyeSVG';
import TeleportToSalesmanSVG from './Icons/TeleportToSalesmanSVG';
import TeleportToUserSVG from './Icons/TeleportToUserSVG';
import { useLocation } from 'react-router-dom';
import { CompanionContext } from '../../../../context/CompanionContext';

/**
 * Companion panel accordion that displays available actions like "Follow user view", "Teleport self to user" etc. for all connected users
 */

interface ICompanionPanelAccordion {
  client: ICompanionClient;
  canAddToSession: boolean;
}

const CompanionPanelAccordion: React.FC<ICompanionPanelAccordion> = ({
  client,
  canAddToSession,
}) => {
  const {
    companionCtx: { followDevice },
    companionDispatch,
  } = useContext(CompanionContext);
  const { translate } = useContext(TranslationContext);
  const [isFollowingUser, setIsFollowingUser] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === '/') {
      setIsFollowingUser(false);
    }
  }, [pathname]);

  const {
    Avatar,
    CanTeleportToSelfToggle,
    CanUserViewToggle,
    DeviceName,
    CanTeleportToUserToggle,
    IsAddedToSession,
    IsInSession,
    IsSelf,
    IsSessionOwner,
    UserId,
    Version,
    Meta,
  } = client;

  const toggleIsAddedToSession = () => {
    if (IsAddedToSession || IsInSession) {
      handleSalesmanRemoveFromSession();
    } else handleSalesmanAddToSession();
    refreshSelesmanPanel();
  };

  const refreshSelesmanPanel = () => {
    unityContext.send('Launcher', 'SalesmanRefresh');
  };

  const handleSalesmanTeleportToUser = () => {
    if (!CanTeleportToUserToggle) return;
    unityContext.send('Launcher', 'SalesmanTeleportToUser', UserId);
    console.log('Send teleport to user ' + UserId);
  };

  const handleSalesmanFollowUser = () => {
    if (followDevice.ShouldFollow && followDevice.UserId === UserId) {
      console.log('stop following', DeviceName, "|", UserId);
      unityContext.send(
        'Launcher',
        'SalesmanFollowUser',
        JSON.stringify({
          userId: UserId,
          shouldFollow: false,
        })
      );
      companionDispatch({
        type: 'SET_FOLLOW_DEVICE',
        payload: { UserId, ShouldFollow: false },
      });
    } else {
      console.log('Start following', DeviceName);
      unityContext.send(
        'Launcher',
        'SalesmanFollowUser',
        JSON.stringify({
          userId: UserId,
          shouldFollow: true,
        })
      );

      companionDispatch({
        type: 'SET_FOLLOW_DEVICE',
        payload: { UserId, ShouldFollow: true },
      });

      setIsFollowingUser(!isFollowingUser);
    }
  };

  const handleSalesmanTeleportToSalesman = () => {
    if (!CanTeleportToSelfToggle) return;
    unityContext.send('Launcher', 'SalesmanTeleportToSalesman', UserId);
    console.log('Send teleport user to salesman ' + UserId);
  };

  const handleSalesmanAddToSession = () => {
    console.log('Add to session send ' + UserId);
    unityContext.send('Launcher', 'SalesmanAddToSession', UserId);
  };

  const handleSalesmanRemoveFromSession = () => {
    console.log('Remove from session send - ' + UserId);
    unityContext.send('Launcher', 'SalesmanRemoveFromSession', UserId);
  };

  return (
    <CompanionPanelAccordionWrapper>
      <CompanionPanelAccordionHeader>
        {Avatar === 'StandaloneAvatar' ? (
          <CompanionClientAvatar
            src='/assets/icons/companion_desktop.svg'
            alt='desktop icon'
          />
        ) : (
          <CompanionClientAvatar
            src='/assets/icons/companion_vr.svg'
            alt='vr icon'
          />
        )}
        <div tw='pl-2 flex-1'>
          <h2 tw='font-medium'>{DeviceName}</h2>
          <div css={[IsSessionOwner && tw`flex items-center`, tw`text-sm`]}>
            {IsSessionOwner && IsInSession && <p>[Eigentümer]</p>}
            {IsInSession ? (
              <p css={[IsSessionOwner && tw`ml-1`]}>{Meta || 'Menü'}</p>
            ) : (
              <p>{Version}</p>
            )}
          </div>
        </div>
        {!IsSelf && canAddToSession && (
          <div>
            <button onClick={toggleIsAddedToSession}>
              {IsAddedToSession || IsInSession ? (
                <i
                  className='fas fa-minus'
                  tw='text-red text-2xl ml-8 hover:text-darkRed duration-200'
                />
              ) : (
                <i
                  className='fas fa-plus'
                  tw='text-red text-2xl ml-8 hover:text-darkRed duration-200'
                />
              )}
            </button>
          </div>
        )}
      </CompanionPanelAccordionHeader>
      <CompanionPanelAccordionBody>
        <Collapse isOpened={(IsAddedToSession || IsInSession) && !IsSelf}>
          <ul tw='flex flex-col space-y-2'>
            {/* Follor user view */}
            <CompanionPanelAccordionListItem onClick={handleSalesmanFollowUser}>
              <EyeSVG color={CanUserViewToggle ? 'red' : '#D1D5Db'} />
              <CompanionPanelFunctionItem
                isActive={CanUserViewToggle}
                css={[
                  CanUserViewToggle &&
                    followDevice.ShouldFollow &&
                    followDevice.UserId === UserId &&
                    tw`text-red`,
                ]}
              >
                {translate('Follow User View')}
              </CompanionPanelFunctionItem>
            </CompanionPanelAccordionListItem>

            {/* Teleport self to user */}
            <CompanionPanelAccordionListItem
              onClick={handleSalesmanTeleportToUser}
            >
              <TeleportToUserSVG
                color={CanTeleportToUserToggle ? 'red' : '#D1D5Db'}
              />
              <CompanionPanelFunctionItem isActive={CanTeleportToUserToggle}>
                {translate('Teleport Self to User')}
              </CompanionPanelFunctionItem>
            </CompanionPanelAccordionListItem>

            {/* Teleport User To self */}
            <CompanionPanelAccordionListItem
              onClick={handleSalesmanTeleportToSalesman}
            >
              <TeleportToSalesmanSVG
                color={CanTeleportToSelfToggle ? 'red' : '#D1D5Db'}
              />
              <CompanionPanelFunctionItem isActive={CanTeleportToSelfToggle}>
                {translate('Teleport User to Self')}
              </CompanionPanelFunctionItem>
            </CompanionPanelAccordionListItem>
          </ul>
        </Collapse>
      </CompanionPanelAccordionBody>
    </CompanionPanelAccordionWrapper>
  );
};

export default CompanionPanelAccordion;
