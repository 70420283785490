import tw, { css, styled } from 'twin.macro';
import { Button } from '../../../styles/components';
import { LinkWithQuery } from '../../LinkWithQuery';

export const ConfigurationStepsNavigationFooter = styled.footer(() => [
  tw`py-4 block border-t w-full overflow-x-hidden fixed bottom-0`,
  css`
    height: 85px;
    background: white;
    z-index: 100;
  `,
]);

export const NavigationButton = styled(Button)<{ isDisabled?: boolean }>(
  ({ isDisabled }) => [
    tw`flex items-center text-lg shadow-bottom px-4 justify-center sm:(px-12) w-full xl:(text-xl w-full)`,
    isDisabled && tw`bg-gray-400 hover:bg-gray-400 cursor-auto`,
  ]
);

export const NavigationButtonsWrapper = tw.div`flex flex-1 mx-auto justify-center max-w-md space-x-2 lg:(space-x-4 justify-center) xl:ml-24`;

export const NavigationLinkWithQuery = tw(LinkWithQuery)`w-full`;
