const TeleportToUserSVG: React.FC<{ color: string }> = ({
  color = '#d1d5db',
}) => {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      x='0px'
      y='0px'
      width={20}
      height={20}
      viewBox='0 0 64 64'
      xmlSpace='preserve'
    >
      <style type='text/css' />
      <path
        id='Union_2473_1_'
        fill={color}
        d='M32,13.7l15.5,15.5h-0.8h-3v3v15.3H20.3V32.2v-3h-3h-0.8L32,13.7 M32,9.5L9.3,32.2h8v18.3
	h29.4V32.2h8L32,9.5L32,9.5z'
      />
      <path
        fill={color}
        d='M26.4,34.8c0.5,0.5,1.4,0.5,2,0l2.2-2.2V39c0,0.8,0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4v-6.4l2.2,2.2
	c0.5,0.5,1.4,0.5,2,0s0.5-1.4,0-2L33,28.2c0,0,0,0,0,0l0,0c0,0,0,0-0.1,0c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.5-0.1
	c-0.2,0-0.4,0-0.5,0.1c0,0,0,0,0,0c-0.2,0.1-0.3,0.2-0.4,0.3l-4.6,4.6C25.8,33.4,25.8,34.3,26.4,34.8z'
      />
    </svg>
  );
};

export default TeleportToUserSVG;
