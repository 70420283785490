import { useContext } from 'react';
import { CompanionContext } from '../../../../context/CompanionContext';
import { CompanionPanelButton } from './styles';
import tw from 'twin.macro';

/**
 * Companion panel trigger
 * @param {boolean} [isDesktop] indicates whether trigger should have some custom styling in desktop mode
 */

interface ICompanionTrigger {
  isDesktop?: boolean;
}

const CompanionPanelTrigger: React.FC<ICompanionTrigger> = ({ isDesktop }) => {
  const { companionCtx, companionDispatch } = useContext(CompanionContext);

  return (
    <CompanionPanelButton
      isPlainText
      css={[
        isDesktop &&
          tw`p-0 w-14 absolute left-6 top-1/2 -translate-y-1/2 hidden lg:block`,
        !isDesktop && tw`p-0 w-8`,
      ]}
      isVisible={
        companionCtx.isAuth &&
        !companionCtx.isCompanionPanelOpen &&
        companionCtx.isSalesmanPanelEnabled
      }
    >
      <img
        src='/assets/icons/headset.svg'
        alt='headset'
        onClick={() => companionDispatch({ type: 'TOGGLE_PANEL' })}
        css={[isDesktop && tw`w-28 block`]}
      />
    </CompanionPanelButton>
  );
};

export default CompanionPanelTrigger;
