import tw, { styled } from 'twin.macro';

export const ShareModal = styled.div(() => [
  tw`absolute w-full scale-y-100 z-50 bg-white border p-4 right-0 top-0`,
]);

export const ShareCloseButton = tw.button`flex items-center`;

export const ShareListItem = tw.li`flex items-center`;

export const ShareListItemCircleWrapper = tw.span`rounded-full w-7 h-7 flex items-center justify-center text-white`;

export const ShareList = tw.ul`grid grid-cols-2 bg-white gap-y-2`;

export const ShareIconText = tw.p`ml-2 text-gray-700 hover:text-black transition-colors`;

export const ShareLink = tw.a`flex items-center`;
