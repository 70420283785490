import { LoadingWrapper, Logo } from './styles';
import 'twin.macro';
import { useEffect } from 'react';

/**
 * Canvas loading screen used as Unity loader
 * @param {number} progress progress status 0-100 indicating Unity loading statusj
 */
interface ICanvasLoadingScreen {
  progress: number;
}

// This loading screen is fixed to load only from 0-30% when progress hits 100%
const CanvasLoadingScreen: React.FC<ICanvasLoadingScreen> = ({ progress }) => {
  useEffect(() => {
    const circleProgressEl = document.querySelector(
      '.progress-circle-prog'
    ) as HTMLDivElement;
    if (circleProgressEl) {
      circleProgressEl.style.strokeDasharray = (progress / 3) * 2 + ' 999';
    }
  }, [progress]);

  return (
    <LoadingWrapper>
      <Logo src='/assets/img/logo.svg' alt='Town Country Haus' />
      <div>
        <link
          href='https://fonts.googleapis.com/css?family=Lato'
          rel='stylesheet'
          type='text/css'
        />

        <div tw='relative'>
          <div className='progress'>
            <svg
              className='progress-circle'
              width='200px'
              height='200px'
              xmlns='http://www.w3.org/2000/svg'
            >
              <circle className='progress-circle-back' cx={80} cy={80} r={45} />
              <circle className='progress-circle-prog' cx={80} cy={80} r={45} />
            </svg>
          </div>
          <div className='progress-text' data-progress={progress / 3}>
            {Math.trunc(progress / 3)}%
          </div>
        </div>
      </div>
    </LoadingWrapper>
  );
};

export default CanvasLoadingScreen;
