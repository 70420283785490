import React from 'react';
import ReactDOM from 'react-dom';
import GlobalStyles from './styles/GlobalStyles';
import App from './App';
import CanvasContextProvider from './context/CanvasContext';
import CompanionContextProvider from './context/CompanionContext';
import TranslationProvider from './context/TranslationContext';

import TagManager from 'react-gtm-module';

import OrderFormContextProvider from './context/OrderFormContext';

console.log('Made by Prographers -- https://prographers.com');
// Here we are just rendering context providers for our <App />, alongside with some global styles

const tagManagerArgsPrographers = {
  gtmId: 'GTM-5D93ZFG',
};

const tagManagerArgsTC = {
  gtmId: 'GTM-TFZN2SS',
};

// Add GTM only on production and page must not be port 8080
if (window.location.href.includes('konfigurator.tc.de') && window.location.port !== '8080') {
  TagManager.initialize(tagManagerArgsPrographers);
  TagManager.initialize(tagManagerArgsTC);
}

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyles />
    <TranslationProvider>
      <CanvasContextProvider>
        <CompanionContextProvider>
          <OrderFormContextProvider>
            <App />
          </OrderFormContextProvider>
        </CompanionContextProvider>
      </CanvasContextProvider>
    </TranslationProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
