import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// To fix react-router scroll issue we add scroll-top on every page transition
// https://stackoverflow.com/questions/36904185/react-router-scroll-to-top-on-every-transition

export default function ScrollToTop() {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // Scroll to #id functionality
  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        document.getElementById(hash.slice(1))?.scrollIntoView();
      }
    }, 500);
  }, [hash]);

  return null;
}
