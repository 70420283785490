import tw, { css, styled } from 'twin.macro';

export const HouseInfoHeader = tw.div`flex items-center mb-5 justify-between`;

export const HouseTitle = tw.h2`font-bold text-3xl`;

export const HouseImage = styled.img`
  width: 100%;
  display: block;
  height: 230px;
  object-fit: cover;
`;

export const HouseInfoWrapper = tw.div`relative h-52 grid items-center`;

export const HouseInfoList = tw.ul`flex text-lg`;

export const HouseInfoListItem = tw.li`flex items-center mr-6 lg:mr-10`;

export const HouseInfoListItemIcon = tw.img`w-4 mr-2`;

export const ComingSoonWrapper = styled.div(() => [
  tw`absolute text-center top-0 text-4xl bg-black bg-opacity-40 z-10 flex justify-center uppercase text-white p-4 h-full`,
  css`
    width: calc(100% - 16px);
    border-radius: 7px;
  `,
]);

export const StyledSlide = styled.div`
  ${tw`shadow-bottom mx-2`}
  border-radius: 7px;
  overflow: hidden;
`;
