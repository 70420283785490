import { Button } from '../../../styles/components';
import {
  ComingSoonWrapper,
  HouseImage,
  HouseInfoHeader,
  HouseInfoList,
  HouseInfoListItem,
  HouseInfoListItemIcon,
  HouseInfoWrapper,
  HouseTitle,
  StyledSlide,
} from './styles';
import 'twin.macro';

import { ConditionalLink } from '../../ConditionalLink';
import { useContext, useEffect, useState } from 'react';
import ShareButton from '../ShareButton';
import { IHouse } from '../../../utils/types';
import { modificationsToQueryString } from '../../../utils/helpers';
import { TranslationContext } from '../../../context/TranslationContext';
import { CanvasContext } from '../../../context/CanvasContext';

/**
 * Component rendered by HouseList component - single house slide in the carousel
 * @param {string} houseName Name of the house
 * @param {number} houseSize Size of the house in square meters
 * @param {number} houseRoomCount How many rooms are in this house
 */

const HouseListItem: React.FC<IHouse> = (props) => {
  const { canvasState: {path} } = useContext(CanvasContext);
  const { translate } = useContext(TranslationContext);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [imgPath, setImgPath] = useState('/assets/img/house.jpg');
  const [shareURL, setShareURL] = useState('');

  // Get house image path
  useEffect(() => {
    async function getHouseImgPath() {
      try {
        const res = await fetch(`${path}/Json/HouseCardConfigs.json`);
        const data = await res.json();
        setImgPath(`${path}/${data[props.Name][0].CameraCaptures[0].ImagePath}`);

        let queryString = `houseName=${props.Name}&size=${props.Size}&rooms=${
          props.Rooms
        }${modificationsToQueryString(data[props.Name][0].Modifications)}`;

        setShareURL(`${window.location.href}configurator/house?${queryString}`);
      } catch (err) {
        console.log(err);
      }
    }
    getHouseImgPath();
  }, [props.Name, props.Size, props.Rooms]);

  return (
    <StyledSlide className={`${props.ComingSoon ? 'relative' : 'group'}`}>
      <ConditionalLink
        to={`/configurator/house?houseName=${props.Name}&rooms=${props.Rooms}&size=${props.Size}`}
        condition={!props.ComingSoon}
      >
        <HouseImage src={imgPath} alt='House placeholder' />
        {props.ComingSoon && (
          <ComingSoonWrapper>
            <div tw='mt-12'>
              Kommt
              <br />
              bald
            </div>
          </ComingSoonWrapper>
        )}
      </ConditionalLink>

      <HouseInfoWrapper>
        <ConditionalLink
          to={`/configurator/house?houseName=${props.Name}&rooms=${props.Rooms}&size=${props.Size}`}
          condition={!isShareModalOpen && !props.ComingSoon}
        >
          <div tw='p-3 xl:p-4 block'>
            <HouseInfoHeader>
              <HouseTitle>{props.Label.Source}</HouseTitle>
              <ShareButton
                isShareModalOpen={isShareModalOpen}
                setIsShareModalOpen={setIsShareModalOpen}
                shareURL={shareURL}
              />
            </HouseInfoHeader>
            <HouseInfoList>
              <HouseInfoListItem>
                <HouseInfoListItemIcon src='/assets/icons/pen_ruler.svg' />
                <span>
                  {props.Size} m<sup>2</sup>
                </span>
              </HouseInfoListItem>
              <HouseInfoListItem>
                <HouseInfoListItemIcon src='/assets/icons/door.svg' />
                <span>
                  {props.Rooms} {translate('Rooms')}
                </span>
              </HouseInfoListItem>
            </HouseInfoList>
            <hr tw='mt-5' />
            <Button isPlainText tw='px-0 ml-auto'>
              <span tw='mr-2 group-hover:text-red transition-colors'>
                Konfigurieren
              </span>
              <i className='fas fa-arrow-right' tw='text-red'></i>
            </Button>
          </div>
        </ConditionalLink>
      </HouseInfoWrapper>
    </StyledSlide>
  );
};

export default HouseListItem;
