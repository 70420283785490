import { Button, Section } from '../../../styles/components';
import {
  Icon,
  MobileMenu,
  Nav,
  PageTitle,
  SearchFiltersForm,
  SearchFiltersWrapper,
  Select,
  SelectFormControl,
  SelectIcon,
  SelectIconsWrapper,
} from './styles';
import {
  Dispatch,
  FormEvent,
  SetStateAction,
  useContext,
  useRef,
  useState,
} from 'react';
import tw from 'twin.macro';
import { houseSizes } from './houseSizes.json';
import { houseCategories } from './houseCategories.json';
import { HouseCategories } from '../../../pages/HouseListPage';
import { CompanionContext } from '../../../context/CompanionContext';
import CompanionPanelTrigger from '../../Companion/CompanionPanel/CompanionPanelTrigger';
import { Label } from '../../../styles/components/formComponents';
import { StyledNav } from '../../Configuration/Navbar/styles';
import DownloadMobileAppButton from '../../DownloadMobileAppButton';
import { TranslationContext } from '../../../context/TranslationContext';

/**
 * House list search component with different filters
 * @param {number} setMinHouseSize
 * @param {number} setMaxHouseSize
 * @param {string} setCategory
 */

interface IHouseListSearch {
  setMinHouseSize: Dispatch<SetStateAction<number | null>>;
  setMaxHouseSize: Dispatch<SetStateAction<number | null>>;
  setCategory: Dispatch<SetStateAction<string>>;
  category: HouseCategories;
}

const HouseListSearch: React.FC<IHouseListSearch> = (props) => {
  const { translate } = useContext(TranslationContext);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const categorySelectEl = useRef<HTMLSelectElement>(null);
  const houseSizeSelectEl = useRef<HTMLSelectElement>(null);
  const { companionCtx } = useContext(CompanionContext);

  function toggleMobileMenu() {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  }

  // Get value from category select input and set filters (don't apply before HAUSSUCHE click)
  function handleChangeCategory() {
    const value = categorySelectEl?.current?.value;
    if (!value) return;
    props.setCategory(value);
  }

  // Get value from house size select input and set filters (don't apply before HAUSSUCHE click)
  function handleChangeHouseSize() {
    const value = houseSizeSelectEl?.current?.value;
    if (!value) return;

    const houseSizesArray = value.split('-');
    const minHouseSize = Number(houseSizesArray[0]);
    const maxHouseSize = Number(houseSizesArray[1]);
    if (houseSizesArray.length === 2) {
      props.setMinHouseSize(minHouseSize);
      props.setMaxHouseSize(maxHouseSize);
    } else if (houseSizesArray[0] === 'all') {
      props.setMinHouseSize(null);
      props.setMaxHouseSize(null);
    } else {
      props.setMaxHouseSize(minHouseSize);
      props.setMinHouseSize(null);
    }
  }

  // Clear filters
  function handleClearInputs() {
    setTimeout(() => {
      categorySelectEl.current!.value = 'Alle';
      houseSizeSelectEl.current!.value = 'all';
    }, 300);
  }

  // Close mobile menu, apply filters
  function handleFilterHouses(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setIsMobileMenuOpen(false);
    handleChangeCategory();
    handleChangeHouseSize();
  }

  return (
    <StyledNav style={{ top: '0' }}>
      <Section>
        <MobileMenu>
          <img src='/assets/img/logo.svg' alt='Town Country Haus logo' />
          <PageTitle css={[companionCtx.isAuth && tw`-mr-4`]}>
            {isMobileMenuOpen ? 'SUCHE NACH EINEM ZUHAUSE' : 'HAUSLISTE'}
          </PageTitle>

          <div tw='flex space-x-4'>
            {companionCtx.isAuth && <CompanionPanelTrigger />}
            <Button isPlainText tw='p-0 w-6' onClick={toggleMobileMenu}>
              {isMobileMenuOpen ? (
                <Icon className='fa fa-times' onClick={handleClearInputs} />
              ) : (
                <Icon className='fa fa-search' />
              )}
            </Button>
          </div>
        </MobileMenu>

        <CompanionPanelTrigger isDesktop />
        <SearchFiltersWrapper
          css={[
            isMobileMenuOpen && tw`scale-y-100`,
            companionCtx.isAuth && tw`lg:(pl-12) xl:(pl-0)`,
          ]}
        >
          <SearchFiltersForm onSubmit={handleFilterHouses}>
            <SelectFormControl tw='mt-0'>
              <Label htmlFor='houseType'>Hauskategorie</Label>
              <SelectIconsWrapper>
                <SelectIcon
                  className='fas fa-home'
                  tw='text-xl text-gray-800'
                />
                <Select id='houseType' ref={categorySelectEl}>
                  {houseCategories.map((category) => (
                    <option key={category} value={category}>
                      {translate(category)}
                    </option>
                  ))}
                </Select>
                <SelectIcon
                  className='fas fa-angle-down'
                  tw='text-red text-3xl right-4'
                />
              </SelectIconsWrapper>
            </SelectFormControl>
            <SelectFormControl tw='border-white'>
              <Label htmlFor='houseSize'>Hausgröße</Label>
              <SelectIconsWrapper>
                <SelectIcon
                  className='fas fa-pencil-ruler'
                  tw='text-xl text-gray-800'
                />
                <Select id='houseSize' ref={houseSizeSelectEl}>
                  {houseSizes.map((houseSize) => (
                    <option key={houseSize.name} value={houseSize.value}>
                      {houseSize.name}
                    </option>
                  ))}
                </Select>
                <SelectIcon
                  className='fas fa-angle-down'
                  tw='text-red text-3xl right-4'
                />
              </SelectIconsWrapper>
            </SelectFormControl>
            <Button type='submit' tw='mt-4 w-full py-4 lg:(flex-1 mt-0)'>
              Haussuche
            </Button>
            <DownloadMobileAppButton />
          </SearchFiltersForm>
        </SearchFiltersWrapper>
      </Section>
    </StyledNav>
  );
};

export default HouseListSearch;
