import tw, { css, styled } from 'twin.macro';
import Unity from 'react-unity-webgl';

export const StyledUnity = styled(Unity)<{ isSmallCanvas: boolean }>(
  ({ isSmallCanvas }) => [
    isSmallCanvas
      ? // Smaller canvas in summary
        `
      height: 100%;

      @media screen and (min-width: 1280px) {
        max-height: 35vh;
      }
       `
      : // Default big canvas
        `
      height: 35vh;

      @media screen and (min-width: 1280px) {
        max-height: 80vh;
        height: 100%;
      }
  `,

    //Styles for both types
    `
      width: 100%;
  `,
  ]
);

export const HouseCanvasPaginationWrapper = tw.div`py-4 px-5 flex max-w-3xl mx-auto items-center justify-center xl:(max-w-none)`;

export const HouseCanvasPagination = tw.ul`flex space-x-2 ml-auto`;

export const HouseCanvasPaginationItem = styled.li<{
  isActive?: boolean;
}>(({ isActive }) => [
  tw`w-4 h-4 bg-gray-300 block cursor-pointer rounded-full hover:bg-gray-400 transition-colors duration-300`,
  isActive && tw`bg-red!`,
]);

export const CanvasFullscreenWrapper = styled.div(() => [
  css`
    height: 100%;

    &:fullscreen {
      .unityInstanceEl {
        max-height: unset;
      }
      canvas {
        height: 100%;
        width: 100%;

        @media screen and (min-width: 1280px) {
          max-height: 100% !important;
        }
      }
      
      button#closeFullscreen {
        position: absolute !important;
        bottom: 30px;
        right: 30px;
        width: 70px;
        height: 70px;
        font-size: 34px;
        display: block;
        z-index: 6;

        @media screen and (min-width: 1280px) {
          bottom: 50px;
          right: 50px;
        }
      }
    }
      button#exitLayout {
        position: absolute !important;
        bottom: 30px;
        right: 30px;
        width: 60px;
        height: 60px;
        font-size: 30px;
        display: block;
        z-index: 5;

        @media screen and (min-width: 1280px) {
          bottom: 50px;
          right: 50px;
        }
      }
    }

    button#closeFullscreen {
      display: none;
    }
  `,
]);
