import { useCallback, useContext, useEffect, useState } from 'react';
import { Divider, Title } from '../../../styles/components';
import {
  CloseModalButton,
  CloseModalIcon,
  MicrosoftBtn,
  ModalBox,
  ModalHeader,
  ModalWrapper,
} from './styles';
import 'twin.macro';
import { CompanionContext } from '../../../context/CompanionContext';
import { TranslationContext } from '../../../context/TranslationContext';
import { CanvasContext, unityContext } from '../../../context/CanvasContext';
import { useLocation } from 'react-router-dom';

/**
 * Modal for logging in to the companion
 */

const CompanionLoginModal = () => {
  const [azureLoginURL, setAzureLoginURL] = useState('');
  const { translate } = useContext(TranslationContext);
  const {
    canvasState: { isCanvasLoaded },
  } = useContext(CanvasContext);
  const {
    companionDispatch,
    companionCtx: { isAuth, isCompanionLoginModalOpen: isModalOpen },
  } = useContext(CompanionContext);
  useState(false);

  const { search, pathname } = useLocation();

  const toggleModal = useCallback(() => {
    if (isAuth) return;
    document.body.classList.toggle('no-scroll'); // turn off scroll when modal is open
    companionDispatch({ type: 'TOGGLE_LOGIN' });
  }, [isAuth, companionDispatch]);

  // Check if it's /login subpage - open modal
  useEffect(() => {
    if (pathname.includes('/login')) {
      companionDispatch({ type: 'TOGGLE_LOGIN' });
    }
  }, [pathname, companionDispatch]);

  // Listen for specific key combination
  useEffect(() => {
    function listenForCombination(e: KeyboardEvent) {
      if (e.key === 'F9' && e.shiftKey && e.ctrlKey) {
        toggleModal();
      }
    }

    // Add event listener
    document.addEventListener('keyup', listenForCombination);

    // Clean up function - remove event listener
    return () => document.removeEventListener('keyup', listenForCombination);
  }, [toggleModal]);

  // Listen for unity login response - triggered when logging to salesman panel
  useEffect(() => {
    unityContext.on('SalesmanToken', (res) => {
      if (res.includes('ERROR')) {
        console.error('SalesmanToken Error');
      } else {
        // Success login res = token
        const payload: { token?: string } = {};
        if (res) payload.token = res;

        companionDispatch({ type: 'AUTH_SUCCESS', payload });
        document.body.classList.remove('no-scroll'); // turn off scroll when modal is closed
      }
    });
  }, [companionDispatch]);

  // Get azure login url
  useEffect(() => {
    if (isCanvasLoaded) {
      const redirectUrl = window.location.origin;

      unityContext.send(
        'Launcher',
        'SalesmanGetAzureLoginUrl',
        `${redirectUrl}`
      );
    }
  }, [isCanvasLoaded]);

  // Check if successfull login with MS provider
  // if so then open settings modal and set token from query params
  useEffect(() => {
    const query = new URLSearchParams(search);
    const token = query.get('token');
    if (token) {
      companionDispatch({ type: 'AUTH_SUCCESS', payload: { token } });
      companionDispatch({ type: 'TOGGLE_SETTINGS' });
      document.body.classList.toggle('no-scroll');
    }
  }, [search, companionDispatch]);

  // Log in with development credentials
  useEffect(() => {
    const query = new URLSearchParams(search);
    const devToken = query.get('azure-dev');
    if (isCanvasLoaded) {
      if (devToken) {
        unityContext.send('Launcher', 'SalesmanAzureAdGetJwtToken', devToken);
      }
    }
  }, [search, isCanvasLoaded]);

  // Wait for callback from loginWithMicrosoft fn
  useEffect(() => {
    unityContext.on(
      'OnSalesmanAzureLoginUrl',
      (microsoftRedirectURL: string) => {
        if (microsoftRedirectURL) {
          setAzureLoginURL(microsoftRedirectURL);
        } else {
          console.error('OnSalesmanAzureLoginURL error');
        }
      }
    );
  }, []);

  return (
    <ModalWrapper isModalOpen={isModalOpen && !isAuth}>
      <ModalBox isModalOpen={isModalOpen && !isAuth}>
        <ModalHeader>
          <Title>{translate('Companion Log in')}</Title>
          <Divider />
          <CloseModalButton onClick={toggleModal}>
            <CloseModalIcon className='fas fa-times'></CloseModalIcon>
          </CloseModalButton>
        </ModalHeader>
        <MicrosoftBtn
          href={azureLoginURL}
          className='btn--microsoft'
          disabled={!isCanvasLoaded}
        >
          <span
            className='fab fa-microsoft'
            tw='border-r  pr-3 mr-2 text-2xl'
          ></span>{' '}
          <span className='border-l'>
            {isCanvasLoaded ? 'Sign in with Microsoft' : 'Loading...'}
          </span>{' '}
        </MicrosoftBtn>
      </ModalBox>
    </ModalWrapper>
  );
};

export default CompanionLoginModal;
