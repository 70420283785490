import { useEffect, useState } from 'react';
import { getMobileOperatingSystem } from '../../utils/helpers';
import { CloseModalIcon } from '../Companion/Modals/styles';
import { ModalWrapper, ShopImg } from './styles';
import 'twin.macro';

const DownloadMobileAppModal = () => {
  const [system, setSystem] = useState('');
  const [isDownloadAppDismissed, setIsDownloadAppDismissed] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const system = getMobileOperatingSystem();
    setSystem(system);
    const isDownloadDismissed = localStorage.getItem('isDownloadAppDismissed');
    if (isDownloadDismissed) {
      setIsDownloadAppDismissed(JSON.parse(isDownloadDismissed));
    }

    const openModal = setTimeout(() => setIsOpen(true), 2000);

    return () => clearTimeout(openModal);
  }, []);

  function handleCloseDownload() {
    localStorage.setItem('isDownloadAppDismissed', 'true');
    setIsOpen(false);
  }

  if ((system !== 'iOS' && system !== 'Android') || isDownloadAppDismissed)
    return null;

  return (
    <ModalWrapper isOpen={isOpen}>
      {system === 'iOS' ? (
        <div>
          <a href='https://www.apple.com/' target='_blank' rel='noreferrer'>
            <ShopImg
              src='/assets/icons/App_Store_Icon.svg'
              alt='Download from App store'
            />
          </a>
        </div>
      ) : (
        <div>
          <a
            href='https://play.google.com/store'
            target='_blank'
            rel='noreferrer'
          >
            <ShopImg
              src='/assets/icons/Google_play_Icon.svg'
              alt='Download from Google play'
            />
          </a>
        </div>
      )}
      <CloseModalIcon
        tw='bg-white text-red hover:text-darkRed fixed right-5 hover:bg-gray-100 transition-colors p-3 rounded-full w-10 h-10 flex items-center justify-center'
        className='fas fa-times'
        onClick={handleCloseDownload}
      ></CloseModalIcon>
    </ModalWrapper>
  );
};

export default DownloadMobileAppModal;
