import { Link } from 'react-router-dom';
import tw, { css, styled } from 'twin.macro';

export const LogoImg = tw.img`mr-24 w-14`;

export const DesktopMenuWrapper = tw.div`xl:flex items-center max-w-6xl mx-auto hidden `;

export const DesktopMenuList = tw.ul`flex justify-between w-full`;

export const DesktopMenuListLink = styled(Link)<{ isActive?: boolean }>(
  ({ isActive }) => [
    tw`uppercase relative py-7 px-2 block font-bold`,
    css`
      &:after {
        content: '';
        ${tw`block w-full bg-red scale-x-0 transform transition-transform duration-300 bottom-0 left-0 absolute h-1`}
        ${isActive && tw`bg-red scale-x-100`}
      }

      &:hover::after {
        ${tw`scale-x-100`}
      }
    `,
  ]
);

export const DesktopMenuListItem = tw.li``;
