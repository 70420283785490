import axios from 'axios';

/**
 * Get auth token, and save it in local storage
 */
export const AuthorizationRequest = async () => {
  const react_app_url = process.env.REACT_APP_API_URL ?? "https://api.towncountry.de:5500";
  const loginUrl = `${react_app_url}/authenticate/v1/signin`;

  if (!localStorage.getItem('order_token')) {
    try {
      const { data } = await axios.post(loginUrl, {
        publicKey: '6e785000-a243-b218-ed36-f27c791d50e0',
        service: 'prographers',
        client_id: 'tc_service_user',
        grant_type: 'password',
      });

      localStorage.setItem('order_token', data.accessToken);
      localStorage.setItem('order_tokenExpiresAt', data.accessTokenExpiresAt);
      localStorage.setItem('order_refreshToken', data.refreshToken);
      localStorage.setItem(
        'order_refreshTokenExpiresAt',
        data.refreshTokenExpiresAt
      );
    } catch (e) {
      console.log(e);
    }
  }
};

export const RefreshAuthorizationToken = async () => {
  const react_app_url = process.env.REACT_APP_API_URL ?? "https://api.towncountry.de:5500";
  const loginUrl = `${react_app_url}/authenticate/v1/refresh-access-token`;
  const refreshToken = localStorage.getItem('order_refreshToken');
  const expirationDate = new Date(
    localStorage.getItem('order_tokenExpiresAt') ?? ''
  );
  const now = new Date();
  const shouldRefresh = expirationDate.getTime() < now.getTime();

  if (refreshToken && shouldRefresh) {
    try {
      const { data } = await axios.post(loginUrl, {
        client_id: 'tc_service_user',
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      });

      localStorage.setItem('order_token', data.accessToken);
      localStorage.setItem('order_tokenExpiresAt', data.accessTokenExpiresAt);
      localStorage.setItem('order_refreshToken', data.refreshToken);
      localStorage.setItem(
        'order_refreshTokenExpiresAt',
        data.refreshTokenExpiresAt
      );
    } catch (e) {
      // Remove tokens if error occurs (refresh token was somehow invalid)
      localStorage.tryRemoveItem("order_token");
      localStorage.tryRemoveItem("order_tokenExpiresAt");
      localStorage.tryRemoveItem("order_refreshToken");
      localStorage.tryRemoveItem("order_refreshTokenExpiresAt");
    }
  }
};

/**
 *
 * @param {string} postCode value from the inquiry form - used to get autocompletion
 * @param {string} city value from the inquiry form - used to get autocompletion
 */
export const getAddresses = async (postCode: string, city: string) => {
  if (postCode?.length < 4 && city?.length < 4) return { addresses: [] };
  const react_app_url = process.env.REACT_APP_API_URL ?? "https://api.towncountry.de:5500";
  const apiUrl = `${react_app_url}/addressfinder/v1/auto-succession`;
  let params = {};
  if (postCode && city) {
    params = {
      postalcode: `${postCode}%`,
      city: `${city}%`,
    };
  } else if (city) {
    params = {
      place: `${city}%`,
    };
  } else {
    params = {
      postalcode: `${postCode}%`,
    };
  }

  const token = localStorage.getItem('order_token');
  try {
    const { data } = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params,
    });

    return data;
  } catch (e) {
    console.log(e);
    return { addresses: [] };
  }
};
