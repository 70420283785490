import { Section } from '../../../styles/components';
import 'twin.macro';
import { DesignsGrid, DesignsGridItem } from './styles';
import { useContext, useEffect, useState } from 'react';
import {
  createImageFromTexture,
  getCanvasModificationsFromQueryString,
  modificationsToQueryString,
  updateQueryParams,
  useQuery,
} from '../../../utils/helpers';
import {
  CanvasContext,
  unityContext,
  updateCanvasConfig,
} from '../../../context/CanvasContext';
import { RouteComponentProps, useLocation, withRouter } from 'react-router';
import { IHouseCardConfig } from '../../../utils/types';
import { v4 as uuidv4 } from 'uuid';
import { TranslationContext } from '../../../context/TranslationContext';

/**
 * House - first step of the configuration process
 */

const HouseConfiguratorHome: React.FC<RouteComponentProps> = ({ history }) => {
  const { translate } = useContext(TranslationContext);
  const {
    canvasState: { gl, path },
    canvasDispatch,
  } = useContext(CanvasContext);
  const { pathname, search } = useLocation();

  const [configs, setConfigs] = useState<any>([]); // Initial configs
  const [savedConfigs, setSavedConfigs] = useState<IHouseCardConfig[]>([]); // Configs saved by user

  const houseName = useQuery().get('houseName') ?? '';
  const houseSize = useQuery().get('size') ?? '';
  const houseRooms = useQuery().get('rooms') ?? '';

  // Get house texture from Unity when saving house
  useEffect(() => {
    unityContext.on('SavedHouseTexture', (texture) => {
      if (gl && texture) {
        const img = createImageFromTexture(gl, texture, 512, 256);

        const newConfig: IHouseCardConfig = {
          id: uuidv4(),
          imgPath: img.src,
          search,
        };

        const newConfigs: IHouseCardConfig[] = [...savedConfigs, newConfig];

        // Set localStorage and state
        localStorage.setItem('houseConfigs', JSON.stringify(newConfigs));
        setSavedConfigs([...newConfigs]);

        // Set index for UI indicator
        canvasDispatch({
          type: 'UPDATE_ACTIVE_CONFIG_INDEX',
          payload: configs.length + savedConfigs.length,
        });
      }
    });
  }, [gl, savedConfigs, configs, search, canvasDispatch]);

  // Try to restore house configs from local storage
  useEffect(() => {
    const savedConfigs = JSON.parse(
      localStorage.getItem('houseConfigs') ?? '[]'
    );
    const filteredConfigs = savedConfigs.filter((config: IHouseCardConfig) =>
      config?.search.includes(houseName)
    );

    setSavedConfigs(filteredConfigs);
  }, [houseName]);

  // Get house config from json file and set state
  useEffect(() => {
    async function getHouseConfigs() {
      try {
        const res = await fetch(`${path}/Json/HouseCardConfigs.json`);
        const data = await res.json();

        setConfigs(data[houseName]);
      } catch (err) {
        console.log(err);
      }
    }
    getHouseConfigs();
  }, [houseName]);

  function handleChangeConfig(config: any, index: number) {
    canvasDispatch({
      type: 'UPDATE_ACTIVE_CONFIG_INDEX',
      payload: index,
    }); // Responsible for showing active indicator in UI

    let queryString = `houseName=${houseName}&rooms=${houseRooms}&size=${houseSize}${modificationsToQueryString(
      config.Modifications
    )}`;

    updateQueryParams(history, pathname, queryString);
    updateCanvasConfig(config.Modifications);
  }

  // Used to prevent from saving again initial configs
  function checkIfSavingPredefinedConfig() {
    let isSavingPredefinedConfig = false;
    configs.forEach((config: any) => {
      const qs = modificationsToQueryString(config.Modifications);
      if (search.includes(qs)) {
        isSavingPredefinedConfig = true;
      }
    });
    return isSavingPredefinedConfig;
  }

  // Save house configuration to state and local storage
  function handleSaveConfig() {
    // Check if saving predefined confing (prevent that action)
    const isSavingPredefinedConfig = checkIfSavingPredefinedConfig();

    const isSameConfig = !!savedConfigs.find(
      (config) => config.search === search
    );
    if (isSameConfig || isSavingPredefinedConfig) return;
    unityContext.send('Launcher', 'RequestSavedHouseTexture', 1);
  }

  function handleLoadConfig(config: IHouseCardConfig, index: number) {
    updateQueryParams(history, pathname, config.search);
    canvasDispatch({
      type: 'UPDATE_ACTIVE_CONFIG_INDEX',
      payload: index,
    });

    const modifications = getCanvasModificationsFromQueryString(config.search);

    updateCanvasConfig(modifications);
  }

  function handleRemoveConfig(savedConfigId: string) {
    // Filter out config that we want to remove
    const filteredConfigs = savedConfigs.filter(
      (savedConfig) => savedConfig.id !== savedConfigId
    );

    // Update local storage
    localStorage.setItem('houseConfigs', JSON.stringify(filteredConfigs));

    // Update state
    setSavedConfigs(filteredConfigs);
  }

  return (
    <Section>
      <h1 tw='mb-2'>{translate('House Design')}</h1>
      <DesignsGrid>
        {configs.map((config: any, index: number) => (
          <DesignsGridItem
            key={index}
            isActive={search.includes(
              modificationsToQueryString(config.Modifications)
            )}
            onClick={() => handleChangeConfig(config, index)}
          >
            <img
              src={`${path}/${config.CameraCaptures[0].ImagePath}`}
              alt='house'
            />
            <figcaption tw='text-center mt-1'>
              {translate(config.ConfigurationName)}
            </figcaption>
          </DesignsGridItem>
        ))}
        {savedConfigs.map((savedConfig: any, index: number) => (
          <DesignsGridItem
            key={index}
            isActive={search === savedConfig.search}
            onClick={() =>
              handleLoadConfig(savedConfig, index + configs.length)
            }
            tw='relative'
          >
            <img src={`${savedConfig.imgPath}`} alt='house' />
            <button
              onClick={() => handleRemoveConfig(savedConfig.id)}
              tw='absolute top-2 right-3 text-red text-lg'
            >
              <i className='fas fa-trash'></i>
            </button>
            <figcaption tw='text-center mt-1'>
              {translate('Saved')} {index + 1}
            </figcaption>
          </DesignsGridItem>
        ))}
        <DesignsGridItem
          tw='flex items-center flex-col justify-center border text-gray-500 py-6'
          onClick={handleSaveConfig}
        >
          <i className='fas fa-plus' tw='text-4xl'></i>
          <p tw='mt-2'>{translate('Save current')}</p>
        </DesignsGridItem>
      </DesignsGrid>
    </Section>
  );
};

export default withRouter(HouseConfiguratorHome);
