import tw, { css, styled } from 'twin.macro';

export const LoadingWrapper = styled.div(() => [
  tw`absolute top-0 transform translate-y-8 left-0 flex flex-col justify-center items-center h-full w-full`,
  css`
    .progress {
      height: 160px;
      width: 160px;
      margin: 20px 0 0 0px 0px;
      animation: spinning 2s linear infinite;
      transform-origin: center;
    }

    .progress-circle-back {
      fill: none;
      stroke: #fff;
      stroke-width: 10px;
    }

    .progress-circle-prog {
      fill: none;
      stroke: #dd1818;
      stroke-width: 10px;
      stroke-dasharray: 0 999;
      stroke-dashoffset: 0px;
      transition: stroke-dasharray 0.7s linear 0s;
    }

    .progress-text {
      text-align: center;
      font-size: 1em;
      position: absolute;
      font-weight: bold;
      color: #dd1818;
      top: 50%;
      left: 50%;
      transform: translateX(-37%) translateY(-50%);
    }

    @keyframes spinning {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(-360deg);
      }
    }
  `,
]);

export const Logo = tw.img`w-1/4 xl:w-1/6 block relative`;

export const LoadingBar = tw.div`border-4 border-black w-1/4 relative h-8 mt-14`;

export const LoadingBarProgress = tw.div`transform origin-left absolute top-0 left-0 transition-transform w-full h-full bg-black`;
