import tw, { css, styled } from 'twin.macro';

export const CompanionPanelWrapper = styled.div<{ isOpen: boolean }>(
  ({ isOpen }) => [
    tw`fixed top-0 flex flex-col left-0 overflow-auto transform scale-0 transition-transform duration origin-top-left duration-200 max-w-sm bg-white border-2 p-6 rounded-xl m-2 shadow-bottom`,
    isOpen && tw`scale-100`,
    css`
      z-index: 101;
      min-width: 376px;
      height: 600px;
      max-height: calc(100vh - 120px);
    `,
  ]
);

export const AvailableDevicesWrapper = styled.div`
  margin-bottom: 1rem;
`;
