import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  AccordionBody,
  AccordionCircle,
  AccordionHeader,
  AccordionWrapper,
  CircleIcon,
} from './styles';
import { Collapse } from 'react-collapse';
import { ICanvasOption } from '../../../utils/types';
import { RouteComponentProps, useLocation, withRouter } from 'react-router';
import {
  getCanvasModificationsFromQueryString,
  queryStringToModifications,
  updateQueryParams,
  validateFacadeModifications,
} from '../../../utils/helpers';
import tw, { css } from 'twin.macro';
import qs from 'qs';
import { updateCanvasConfig, CanvasContext } from '../../../context/CanvasContext';
import { TranslationContext } from '../../../context/TranslationContext';

/**
 * House configurator accordion component - used for displaying available modifications e.g. rooftop colors
 * @param {string} houseCategory category of the house visible at the top right corner of each house carousel
 * @param {number} minHouseSize miniumum house size value used in filtering out unsuitable houses
 * @param {number} maxHouseSize maximum house size value used in filtering out unsuitable houses
 * @param {number} houseCategory category of the house visible at the top right corner of each house carousel
 */

interface IConfigurationAccordion extends RouteComponentProps {
  configurationDisplayName: string;
  configurationName: string;
  configurationOptions: any[];
  activeAccordion: string;
  setActiveAccordion: Dispatch<SetStateAction<string>>;
}

const ConfigurationAccordion: React.FC<IConfigurationAccordion> = ({
  configurationName,
  configurationDisplayName,
  configurationOptions,
  history,
  activeAccordion,
  setActiveAccordion,
}) => {
  const { canvasState: {path} } = useContext(CanvasContext);
  const { translate } = useContext(TranslationContext);
  const { canvasState: { filteredModifications } } = useContext(CanvasContext);
  const { search, pathname } = useLocation();
  const [activeOption, setActiveOption] = useState(configurationOptions[0]);
  const isAccordionOpen = configurationName === activeAccordion;
  //const houseName = useQuery().get('houseName') ?? '';

  /*// Filter doors based on door type
  useEffect(() => {
    if (configurationName === 'Doors') {
      const params = qs.parse(search, { ignoreQueryPrefix: true });

      let filteredConfigurationOptions = configurationOptions;

      if (params?.DoorType === 'DoorShort') {
        filteredConfigurationOptions = configurationOptions.filter(
          (option) => !option.Name.includes('_Wide')
        );
      } else if (params?.DoorType === 'DoorWide') {
        filteredConfigurationOptions = configurationOptions.filter((option) =>
          option.Name.includes('_Wide')
        );
      }

      if (activeAccordion !== 'Doors') {
        // Check if doors from query params are in filtered doors list - this is the case, where the link is already configured with doors, and we don't want to overwrite them,
        // with filtered default ones.
        const filteredDoorNames = filteredConfigurationOptions.map(
          (option) => option.Name
        );
        const doorNameIndex = filteredDoorNames.indexOf(params.Doors);
        if (doorNameIndex === -1) {
          params.Doors = filteredConfigurationOptions[0]?.Name;

          updateQueryParams(history, pathname, qs.stringify(params));
        }
      }
      setAvailableOptions(filteredConfigurationOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);*/

  // Restore active circle in the UI (based on the active modifications from query params)
  // Useful when you go directly to the URL
  useEffect(() => {
    const modifications = getCanvasModificationsFromQueryString(search);

    configurationOptions.forEach((option: any, index) => {
      modifications.forEach((modification) => {
        if (
          modification.group === option.Id.Group &&
          modification.id === option.Id.Id
        ) {
          setActiveOption(configurationOptions[index]);
        }
      });
    });
  }, [configurationOptions, search, activeAccordion]);

  useEffect(() => {
    const setConfigurationActive = (pathname : string) => {
      let modificationArray = queryStringToModifications(pathname);
      if (modificationArray[activeOption.Id.Group] !== activeOption.Id.Id) {
        configurationOptions.forEach((option: any, index) => {
          if (
            modificationArray[activeOption.Id.Group] === option.Id.Id
          ) {
            setActiveOption(configurationOptions[index]);
          }
        });
      }
    }

    return history.listen((location) => {
      setConfigurationActive(location.pathname);
    });
  }, [history]);

  function toggleAccordion() {
    if (activeAccordion === configurationName) {
      setActiveAccordion('');
    } else {
      setActiveAccordion(configurationName);
    }
  }

  // On change method that is fired whenever an option in accordion (circle) is beeing clicked
  function handleChange(option: ICanvasOption, index: number) {
    const modificationName = option.Id.Group;
    // Set active color
    setActiveOption(configurationOptions[index]);

    const newModification = {
      [modificationName]: option.Id.Id,
    };

    const currentParams = qs.parse(search, { ignoreQueryPrefix: true });

    // New search query params obj => stringify all params
    let newModifications = Object.assign({}, currentParams, newModification);

    if (pathname.includes('/facade')) {
      newModifications = validateFacadeModifications(
        modificationName,
        newModifications,
        option.Id.Id
      );
    }
    const newSearchParams = qs.stringify(newModifications);

    updateCanvasConfig([{ group: option.Id.Group, id: option.Id.Id }]);

    updateQueryParams(history, pathname, newSearchParams);
  }

  return filteredModifications[configurationName]?.IsEnabled? (
    <AccordionWrapper id={`${configurationName}`}>
      <AccordionHeader onClick={toggleAccordion}>
        <h1>{translate(configurationDisplayName)}</h1>
        <i
          className='fas fa-chevron-down'
          tw='text-red transform transition-transform duration-300'
          css={[isAccordionOpen && tw`rotate-180`]}
        ></i>
      </AccordionHeader>
      <AccordionBody>
        {/* Active item */}
        <div
          tw='mb-2 mt-2 flex items-center cursor-pointer'
          onClick={toggleAccordion}
        >
          <AccordionCircle
            css={[
              !activeOption.Display.Icon &&
              css`
                  background: ${activeOption?.Display?.Tint};
                `,
            ]}
            isActive
          >
            {activeOption.Display.Icon && (
              <CircleIcon
                iconPath={`${path}/${activeOption.Display.Icon}`}
                color={activeOption.Display.Tint}
              />
            )}
          </AccordionCircle>
          <p tw='ml-6'>
            {activeOption.Display?.Label?.Source
              ? translate(
                activeOption.Display.Label.Source
              )
              : 'Keiner'}
          </p>
        </div>
        {/* Available options */}
        <Collapse isOpened={isAccordionOpen}>
          <div tw='flex flex-wrap'>
            {configurationOptions.map((option: any, index: number) => (
              filteredModifications[configurationName]?.ConfigElements[option.Name]?.IsEnabled !== false || null || undefined ? (<AccordionCircle
                key={index}
                css={[
                  css`
                    border-color: #fff;
                  `,
                  !option.Display.Icon &&
                  css`
                      background: ${option?.Display?.Tint};
                      border-color: ${option?.Display?.Tint};
                    `,
                ]}
                onClick={() => handleChange(option, index)}
                isActive={
                  activeOption.Id.Group === option.Id.Group &&
                  activeOption.Id.Id === option.Id.Id
                }
                tw='mr-3 mb-3'
              >
                {option?.Display?.Icon && (
                  <CircleIcon
                    iconPath={`${path}/${option.Display.Icon}`}
                    color={option.Display.Tint}
                  />
                )}
              </AccordionCircle>
              ) : null
            ))}
          </div>
        </Collapse>
      </AccordionBody>
    </AccordionWrapper>
  ) : null;
};

export default withRouter(ConfigurationAccordion);
