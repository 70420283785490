import tw, { css, styled } from 'twin.macro';

/**
 * Label for naming input fields
 */
export const Label = tw.label`font-bold mb-1`;

/**
 * Form grouping element for displaying them inline
 */
export const FormControl = tw.div`flex flex-col relative`;

/**
 * Global input component
 */
interface IInput {
  hasError?: boolean;
}

export const Input = styled.input<IInput>(({ hasError }) => [
  tw`rounded px-3 py-2 border border-gray-400 w-full`,
  hasError && tw`border-red`,
  css`
    outline: none;

    &:focus-visible {
      box-shadow: 0 0 0px 1px #e20000;
      border: 1px solid #e20000;
    }
  `,
]);

/**
 * Global text area component
 */
export const TextArea = styled.textarea<IInput>(({ hasError }) => [
  tw`rounded resize-none px-3 py-2 border border-gray-400 w-full`,
  hasError && tw`border-red`,
  css`
    outline: none;

    &:focus-visible {
      box-shadow: 0 0 0px 1px #e20000;
      border: 1px solid #e20000;
    }
  `,
]);

/**
 * Global radio input (red)
 */
export const RadioInput = styled.input`
  appearance: none;
  position: relative;
  cursor: pointer;

  &:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: 2px;
    left: -1px;
    position: relative;
    background-color: white;
    content: '';
    display: block;
    border: 1px solid #5a5a5a;
  }

  &:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: 1px;
    left: -1px;
    position: relative;
    background-color: white;
    content: '';
    display: inline-block;
    border: 1px solid #e20000;
  }

  &:checked:before {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    top: 7px;
    left: 3px;
    z-index: 1;
    position: absolute;
    background-color: #e20000;
    content: '';
    display: inline-block;
  }
`;

export const FormErrorMsg = styled.p<{ isVisible: boolean }>(
  ({ isVisible }) => [
    tw`text-red mt-1 text-sm hidden`,
    isVisible && tw`block absolute`,
  ]
);

export const ErrorBorder: React.FC<{ isVisible: boolean }> = ({
  children,
  isVisible,
}) => (
  <div css={[isVisible && tw`border border-red rounded-lg ml-2 p-2`]}>
    {children}
  </div>
);
