import React, { createContext, useState } from 'react';

export interface IOrderFormState {
  values: {
    [key: string]: string | boolean;
    gender: string;
    phone: string;
    firstName: string;
    lastName: string;
    email: string;
    city: string;
    postCode: string;
    street: string;
    houseNumber: string;
    isContactAccepted: boolean;
    howDidYouHearAboutUs: string;
    description: string;
  };
  errors: {};
  clearForm: () => void;
}

interface IOrderFormContext {
  orderFormState: IOrderFormState;
  setOrderFormState: any;
}

export const OrderFormContext = createContext<IOrderFormContext>(null!);
export const formInitialValues = {
  gender: 'He',
  phone: '',
  firstName: '',
  lastName: '',
  email: '',
  city: '',
  postCode: '',
  street: '',
  houseNumber: '',
  isContactAccepted: false,
  howDidYouHearAboutUs: 'Not specified',
  description: '',
};

const OrderFormContextProvider: React.FC = ({ children }) => {
  const [state, setState] = useState({
    values: formInitialValues,
    errors: {},
    clearForm: () => {
      setState({ ...state, values: formInitialValues });
    },
  });

  return (
    <OrderFormContext.Provider
      value={{ orderFormState: state, setOrderFormState: setState }}
    >
      {children}
    </OrderFormContext.Provider>
  );
};

export default OrderFormContextProvider;
